import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import BaseInput from "@reusables/BaseInput";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BasePhoneInput from "@reusables/BasePhoneInput";
import BaseModal from "@reusables/Modals/BaseModal";
import { removeEmpty, toastError } from "@helpers/utils";
import { Supplier } from "@/types/general";
import { zodResolver } from "@hookform/resolvers/zod";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { useUpdateSupplierMutation } from "@redux/features/suppliers/suppliersApi";
import { contactScheme } from "@components/Dashboard/pages/Suppliers/components/tabs/TabsController";

type CreateTheirReferenceModalProps = {
    supplier?: Supplier.Extended;
    isOpen: boolean;
    onClose: () => void;
    onCreate: (contact: Supplier.Extended) => void;
}

const formScheme = z.object({
    contacts: contactScheme
});

type FormTyping = z.infer<typeof formScheme>

export default function CreateTheirReferenceModal({ supplier, isOpen, onClose, onCreate}: CreateTheirReferenceModalProps) {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general.modals.createTheirReference"});

    const [updateSupplier, { isLoading: isCreationLoading }] = useUpdateSupplierMutation();

    const {control, handleSubmit, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const onSubmit = handleSubmit((data) => {
        if(!supplier)  {
            toast.error("Supplier is not selected");
            return;
        }

        updateSupplier({
            id: supplier?.id,
            name: supplier.name,
            code: supplier.code,
            ...(supplier && {contacts: data.contacts?.filter(con => !_.isEmpty(removeEmpty(con))).map(contact => {
                return {
                    id: contact.id,
                    name: contact.name as string,
                    phone: contact.phone as string,
                    email: contact.email as string
                }
            })}),
            deleted_contacts: [],
            deleted_returns: [],
        }).unwrap()
        .then((data) => {
            onCreate(data);
        })
        .catch((error) => {
            toastError(error);
        });
    })

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading}
        >
            <form className="space-y-8" onSubmit={onSubmit}>
                <div className="modal-title">{t("heading")}</div>

                <BaseInputsGrid cols={1}>
                    <Controller
                        name="contacts.0.name"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.name")}
                            />
                        )}
                    />

                    <Controller
                        name="contacts.0.phone"
                        control={control}
                        render={({field, fieldState}) => (
                            <BasePhoneInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.phone")}
                                variant="custom"
                            />
                        )}
                    />

                    <Controller
                        name="contacts.0.email"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.email")}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <BaseButton
                    buttonWidth="100%"
                    text={t("buttons.create")}
                    size="md"
                    type={"submit"}
                />

            </form>
        </BaseModal>
    )
}
