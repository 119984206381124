import React, { useEffect } from "react";
import { SaleOrder } from "@/types/general";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import GeneralSection from "./parts/GeneralSection";
import ReferencesSection from "./parts/ReferencesSection";
import BaseButton from "@reusables/BaseButton";
import AddressSection from "./parts/AddressSection";
import ProductSection from "./parts/ProductSection";
import dayjs from "dayjs";
import { SaleOrdersFormTyping, saleOrdersScheme } from "./types";
import { toast } from "react-toastify";
import { normalizePrice } from "@helpers/utils";
import LanguageSection from "./parts/LanguageSection";
import { DevTool } from "@hookform/devtools";

interface MutationLayoutProperties {
    saleOrder: SaleOrder.Extended | undefined;
    onSubmit: (args: SaleOrdersFormTyping) => void;
}

export default function MutationLayout(props: MutationLayoutProperties) {
    const form = useForm<SaleOrdersFormTyping>({
        resolver: zodResolver(saleOrdersScheme),
        defaultValues: {
            mode: "creation",
            is_editable: true,
            is_billing_for_delivery: false,
            order_date: dayjs(),
            lines: [],
            is_invoiced: false,
            deleted_lines: []
        }
    });

    useEffect(() => {
        if (props.saleOrder) {
            form.setValue("mode", "editing");
            form.setValue("is_editable", props.saleOrder.is_editable);

            form.setValue("customer", props.saleOrder.customer);
            form.setValue("order_date", props.saleOrder.order_date);
            form.setValue("preferred_delivery_date", props.saleOrder.preferred_delivery_date);
            form.setValue("our_reference", props.saleOrder.our_reference);
            form.setValue("their_reference", props.saleOrder.their_reference);
            form.setValue("payment_terms", props.saleOrder.payment_terms);
            form.setValue("delivery_terms", props.saleOrder.delivery_terms);
            form.setValue("language", props.saleOrder.language);
            if (props.saleOrder.billing_address)
                form.setValue("billing_address", {
                    name: props.saleOrder.billing_address.name,
                    street: props.saleOrder.billing_address.street,
                    city: props.saleOrder.billing_address.city,
                    country: props.saleOrder.billing_address.country
                });
            form.setValue("delivery_address", props.saleOrder?.delivery_address);

            form.setValue("is_billing_for_delivery", props.saleOrder.is_billing_for_delivery);

            const combinedLines = props.saleOrder.lines.map((line, index) => {
                if (line.type === "line") {
                    return {
                        type: line.type,
                        id: line.data.id,
                        data: {
                            ...line.data,
                            product: {
                                ...line.data.product,
                                prices: {
                                    ...line.data.product.prices,
                                    purchase_price: normalizePrice(line.data.product.prices.cost_price)
                                },
                            },
                            unit_price: normalizePrice(line.data.unit_price)
                        }
                    }
                } else if (line.type === "group") {
                    return [
                        {
                            type: line.type,
                            group_id: line.id,
                            name: line.name,
                            module: line.module,
                            key: index.toString()
                        },
                        ...line.data.map(groupLine => ({
                            type: line.type,
                            group_id: line.id,
                            id: groupLine.id,
                            module: line.module,
                            key: index.toString(),
                            data: {
                                ...groupLine,
                                product: {
                                    ...groupLine.product,
                                    prices: {
                                        ...groupLine.product.prices,
                                        purchase_price: normalizePrice(groupLine.product.prices.cost_price)
                                    }
                                },
                                unit_price: normalizePrice(groupLine.unit_price)
                            }
                        }))
                    ]
                }
            }).flat();
            
            form.setValue("lines", combinedLines as SaleOrdersFormTyping["lines"]);

            form.setValue("deleted_lines", []);
        }
    }, [props.saleOrder]);

    const onSubmit = form.handleSubmit((data: SaleOrdersFormTyping) => {
        // Unused, because we forbid editing SO if it's invoiced
        // if (props.saleOrder?.is_invoiced) {
        //     for (let i = 0; i < form.getValues("lines").length; i++) {
        //         const originalQuantity = props.saleOrder?.lines[i].data?.quantity;
        //         const newQuantity = form.getValues("lines")[i].data!.quantity;

        //         // If the new quantity is less than the original, show error and stop the mutation
        //         if (newQuantity < originalQuantity) {
        //             toast.error(`Quantity for product ${form.getValues("lines")[i].data?.product.name} cannot be less than the original quantity.`);
        //             return;
        //         }
        //     }
        // }

        props.onSubmit(data)
    }, console.error);

    return (
        <FormProvider {...form}>
            <div className="space-y-[40px]">
                <GeneralSection />
                <ReferencesSection />
                <AddressSection />
                <LanguageSection />
                <ProductSection />
                <div className="flex justify-center">
                    <BaseButton text={"Save"} size={"md"} buttonWidth={"200px"} onClick={() => onSubmit()} />
                </div>
            </div>
            <DevTool control={form.control} />
        </FormProvider>
    );
}