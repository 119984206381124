import React, { useEffect, useState } from "react";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Supplier } from "@/types/general";
import { requiredIfAnyFilled } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import GeneralTab from "./GeneralTab";
import SupplierStepper from "../Stepper/SupplierStepper";
import SettingsTab from "./SettingsTab";
import AddressTab from "./AddressTab";
import BaseButton from "@reusables/BaseButton";
import { createPortal } from "react-dom";
import { refinements } from "@helpers/refinements";
import _ from "lodash";

export const contactScheme = z.array(
    z.object({
        id: z.number().optional(),
        name: z.string().optional(),
        phone: z.string().optional(),
        email: z.string().email().optional()
    }).superRefine((scheme, ctx) => {
        requiredIfAnyFilled(scheme, ["phone", "email"])
            .forEach(key => {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: {
                        i18n: "general.validation.global.required"
                    },
                    path: [key]
                });
            });
    })
);

const formScheme = z.object({
    mode: z.union([z.literal("creation"), z.literal("editing")]),

    name: z.string().trim(),
    code: z.string().trim().optional(),
    vat: z.string().trim().refine(refinements.validateCRN.refine, refinements.validateCRN.message).optional(),

    contacts: contactScheme,

    deleted_contacts: z.array(z.number()),

    billing_address: z.object({
        name: z.string().trim().nullish(),
        street: z.string().trim().nullish(),
        street_2: z.string().trim().nullish(),
        zipcode: z.string().trim().nullish(),
        city: z.string().trim().nullish(),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
            dial_code: z.string()
        }).nullish(),
        phone: z.string().trim().nullish(),
        email: z.string().trim().email().nullish(),
        is_used_for_return: z.boolean().nullish()
    }).superRefine((scheme, ctx) => {
        const hasNonNullishFields = _.some(
            _.omit(scheme, ["name", "is_used_for_return"]),
            (value) => !_.isNil(value) && value !== ""
        );
    
        if (hasNonNullishFields && (!scheme.name || scheme.name.trim() === "")) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Required",
                path: ["name"],
            });
        }
    }),

    returns: z.array(
        z.object({
            id: z.number().optional(),
            name: z.string().trim().nullish(),
            street: z.string().trim().nullish(),
            street_2: z.string().trim().nullish(),
            zipcode: z.string().trim().nullish(),
            city: z.string().trim().nullish(),
            country: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string(),
                dial_code: z.string()
            }).nullish(),
            contact_person: z.string().trim().nullish(),
            phone: z.string().trim().nullish(),
            email: z.string().trim().email().nullish(),
            is_primary: z.boolean().optional()
        }).superRefine((scheme, ctx) => {
            const hasNonNullishFields = _.some(
                _.omit(scheme, ["name", "is_primary", "id"]),
                (value) => !_.isNil(value) && value !== ""
            );
        
            if (hasNonNullishFields && (!scheme.name || scheme.name.trim() === "")) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Required",
                    path: ["name"],
                });
            }
        })
    ).nullish(),

    deleted_returns: z.array(z.number()),

    currency: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
        symbol: z.string(),
        isBase: z.boolean()
    }).nullish(),

    tax_rate: z.object({
        id: z.number(),
        name: z.string().nullish(),
        rate: z.coerce.number()
    }).nullish(),

    payment_terms: z.object({
        id: z.number(),
        name: z.string(),
        days: z.number()
    }).nullish(),

    delivery_terms: z.object({
        id: z.number(),
        name: z.string()
    }).nullish(),

    language: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }).nullish()
});

export type SupplierFormTyping = z.infer<typeof formScheme>;

interface TabsControllerProps {
    supplier?: Supplier.Extended;
    onSubmit: (data: SupplierFormTyping) => void;
}

export default function TabsController({ supplier, onSubmit }: TabsControllerProps) {
    const { t } = useTranslation("", { keyPrefix: "suppliers.general.tabs" });

    const methods = useForm<SupplierFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            contacts: [{}],
            returns: [{}],
            deleted_contacts: [],
            deleted_returns: []
        }
    });

    const [activeTab, setActiveTab] = useState<number>(0);

    const onFormSubmit = methods.handleSubmit(onSubmit, console.error);

    useEffect(() => {
        if (supplier) {
            methods.reset({
                mode: "editing",
                name: supplier.name,
                code: supplier.code,
                vat: supplier.vat ?? undefined,
                contacts: supplier.contacts,
                billing_address: supplier.billing ?? {},
                returns: supplier.returns.length ? supplier.returns : [{}],
                currency: supplier.currency ?? undefined,
                tax_rate: supplier.tax_rate ?? undefined,
                payment_terms: supplier.payment_terms ?? undefined,
                delivery_terms: supplier.delivery_terms ?? undefined,
                language: supplier.language ?? undefined,
                deleted_contacts: [],
                deleted_returns: []
            });
        } else {
            methods.reset({
                mode: "creation",
                contacts: [{}],
                returns: [{}],
                deleted_contacts: [],
                deleted_returns: []
            });
        }
    }, [supplier, methods.reset]);

    const supplierHeaderElement = document.getElementById("supplier-header");

    return (
        <FormProvider {...methods}>
            {
                supplier && supplierHeaderElement && createPortal(
                    <BaseButton
                        text={t("save")}
                        size={"md"}
                        onClick={() => onFormSubmit()}
                    />,
                    supplierHeaderElement
                )
            }
            <SupplierStepper
                tabs={[
                    {
                        title: t => t("general.heading"),
                        content: <GeneralTab
                            onNext={(e) => {
                                e.preventDefault();
                                setActiveTab(1);
                            }}
                        />
                    },
                    {
                        title: t => t("address.heading"),
                        content: <AddressTab
                            onPrev={() => {
                                setActiveTab(0);
                            }}
                            onNext={(e) => {
                                e.preventDefault();
                                setActiveTab(2);
                            }}
                        />
                    },
                    {
                        title: t => t("settings.heading"),
                        content: <SettingsTab
                            onPrev={() => {
                                setActiveTab(1);
                            }}
                            onNext={onFormSubmit}
                        />
                    }
                ]}

                manual={{
                    activeTab: activeTab,
                    onClick: (tab) => {
                        setActiveTab(tab);
                    }
                }}
            />
        </FormProvider>
    );
}