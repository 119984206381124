import {
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "@redux/api/internalApiSlice";
import { Customer } from "@/types/general";

export const customersApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomers: builder.query<Customer.Slim[], void>({
            query: () => ({
                url: `customer/slim`,
            }),
            transformResponse,
            providesTags: [{ type: "Customer", id: "LIST" }]
        }),

        getCustomersFull: builder.query<PaginationResponse<Customer.Root>, PaginationRequest<Customer.DTO.Filters, Customer.DTO.OrderBy>>({
            query: (req) => ({
                url: `customer`,
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["valid_from", "valid_to"]
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({id}) => ({type: 'Customer' as const, id})),
                        {type: 'Customer', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Customer', id: 'PARTIAL-LIST'}]
        }),

        getModuleCustomers: builder.query<Customer.Slim[], string>({
            query: (module) => ({
                url: `${module}/customer`
            }),
            transformResponse,
            providesTags: (result) => [{ type: "Customer", id: "LIST" }]
        }),

        getCustomer: builder.query<Customer.Extended, number>({
            query: (id) => ({
                url: `customer/${id}`,
                parseDates: ["valid_from", "valid_to"]
            }),
            transformResponse,
            providesTags: result => result ? [{ type: "Customer", id: result.id }] : []
        }),

        getModuleCustomer: builder.query<Customer.Extended, { module: string, id: number }>({
            query: ({ module, id }) => ({
                url: `${module}/customer/${id}`,
                parseDates: ["valid_from", "valid_to"]
            }),
            transformResponse,
            providesTags: (result) => result ? [{ type: "Customer", id: result.id }] : []
        }),

        createCustomer: builder.mutation<Customer.Extended, Customer.DTO.Create>({
            query: (body) => ({
                url: `customer`,
                method: "POST",
                data: body,
                parseDates: ["valid_from", "valid_to"]
            }),
            transformResponse,
            invalidatesTags: [{ type: "Customer", id: "LIST" }, {type: "Customer", id: "PARTIAL-LIST"}],

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdCustomer } = await queryFulfilled;
                    const createResult = dispatch(customersApi.util.updateQueryData("getCustomers", undefined, (draft) => {
                        draft.unshift({
                            id: createdCustomer.id,
                            name: createdCustomer.name,
                            code: createdCustomer.code,
                        });
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        updateCustomer: builder.mutation<Customer.Extended, Customer.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `customer/${id}`,
                method: "PUT",
                data: body,
                parseDates: ["valid_from", "valid_to"]
            }),
            transformResponse,
            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: updatedCustomer } = await queryFulfilled;
            //         const updateResult = dispatch(customersApi.util.updateQueryData("getCustomers", undefined, (draft) => {
            //             const index = draft.findIndex((customer) => customer.id === updatedCustomer.id);
            //             draft[index] = {
            //                 id: updatedCustomer.id,
            //                 name: updatedCustomer.name,
            //                 code: updatedCustomer.code
            //             };
            //         }));
            //     } catch (err) {
            //         console.error(err);
            //     }
            // },
            invalidatesTags: (result, error, { id }) => [{ type: "Customer", id }]
        }),

        deleteCustomer: builder.mutation<void, number>({
            query: (id) => ({
                url: `customer/${id}`,
                method: "DELETE"
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(customersApi.util.updateQueryData("getCustomers", undefined, (draft) => {
                        const index = draft.findIndex((customer) => customer.id === id);
                        draft.splice(index, 1);
                    }));
                } catch (e) {
                    console.error(e);
                }
            },
            invalidatesTags: (result, error, id) => [{ type: "Customer", id }]
        }),

        createCustomerBillingAddress: builder.mutation<Customer.Components.BillingAddress, Customer.Components.DTO.CreateBilling & {id: number}>({
            query: ({ id, ...body }) => ({
                url: `customer/${id}/billing-address`,
                method: "POST",
                data: body
            }),
            invalidatesTags: (result, error, { id }) => [{ type: "Customer", id }]
        }),

        createCustomerDeliveryAddress: builder.mutation<Customer.Components.DeliveryAddress, Customer.Components.DTO.CreateDelivery & {id: number}>({
            query: ({ id, ...body }) => ({
                url: `customer/${id}/delivery-address`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: (result, error, { id }) => [{ type: "Customer", id }]
        }),
    })
});

export const {
    useGetCustomersQuery,
    useGetCustomersFullQuery,
    useGetModuleCustomersQuery,
    useGetCustomerQuery,
    useGetModuleCustomerQuery,
    useCreateCustomerMutation,
    useUpdateCustomerMutation,
    useDeleteCustomerMutation,
    useCreateCustomerBillingAddressMutation,
    useCreateCustomerDeliveryAddressMutation
} = customersApi;