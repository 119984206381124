import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import React, { useEffect, useState } from "react";
import { productsApi } from "@redux/features/products/productsApi";

import { ReactComponent as PercentageIcon } from "@assets/icons/ic_percentage.svg";
import { z } from "zod";
import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useTranslation } from "react-i18next";
import { normalizePrice } from "@helpers/utils";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import { Customer, Product } from "@/types/general";
import { useGetModuleCustomerQuery } from "@redux/features/customers/customersApi";
import dayjs from "dayjs";
import DiscountCard from "./DiscountCard";
import { Fade, Stack } from "@mui/material";
import i18n from "i18next";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { useGetModuleTaxesQuery } from "@redux/features/taxes/taxesApi";
import { SaleOrdersFormTyping } from "../../types";
import BaseTooltip from "@/components/reusables/BaseTooltip";
import { HelpCircle } from "lucide-react";
import BaseLabel from "@/components/reusables/BaseLabel";

const formScheme = z.object({
    id: z.number().nullish(),
    comment: z.string().nullish(),
    product: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
        is_service: z.boolean(),
        in_stock: z.number().nullish(),
        min_sale_quantity: z.number().nullish(),
        prices: z.object({
            purchase_price: z.number(),
            selling_price: z.number(),
        }),
        has_bom: z.boolean(),
        is_component: z.boolean()
    }),
    quantity: z.coerce.number().positive(),
    unit_price: z.coerce.number().min(0).nullish(),
    discount: z.coerce.number().min(0).max(100).optional(),
    tax: z.object({
        id: z.number(),
        name: z.string().nullish(),
        rate: z.number()
    }).nullish(),
});

type FormTyping = z.infer<typeof formScheme>;

// Define a type for a discount option
export interface DiscountOption {
    id: string;
    name?: string;
    discount: number | undefined;
    salePrice: number;
    calculated_price: number | undefined;
    min_quantity?: number;
    valid_from?: dayjs.Dayjs;
    valid_to?: dayjs.Dayjs | null;
}

export interface AddProductModalProps {
    isOpen: boolean;
    onClose: () => void;

    onLineMutation: (newOrder: FormTyping & { in_stock?: number | null; min_sale_quantity?: number | null }) => void;
    customer?: Customer.Root;
    selectedLine?: SaleOrdersFormTyping["lines"][0];
}

export default function AddProductModal({
                                            isOpen,
                                            onClose,
                                            onLineMutation,
                                            customer,
                                            selectedLine
                                        }: AddProductModalProps): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders.modals.addProduct" });

    const dispatch = useAppDispatch();
    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency.symbol);


    const { data: taxesOptions, isLoading: isTaxesOptionsLoading } =
        useGetModuleTaxesQuery("sale", { skip: !isOpen });

    // State to store the discount options and the selected discount
    const [discountOptions, setDiscountOptions] = useState<DiscountOption[]>([]);
    const [selectedDiscount, setSelectedDiscount] = useState<DiscountOption>();

    const [productsOptions, setProductsOptions] = useState<Product.Slim[]>([]);
    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);

    const { data: selectedCustomer, isLoading: isSelectedCustomerLoading } = useGetModuleCustomerQuery(
        {module: "sale", id: customer?.id as number},
        {skip: !customer}
    );

    const [isExtendedProductGetLoading, setIsExtendedProductGetLoading] = React.useState(false);

    const { control, ...form } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    const selectedProduct = useWatch({
        control,
        name: "product"
    });

    useEffect(() => {
        form.reset();
        if (selectedLine && selectedLine.data) {
            form.setValue("id", selectedLine.id);
            form.setValue("comment", selectedLine.data.comment);
            form.setValue("product", selectedLine.data.product);
            form.setValue("quantity", selectedLine.data.quantity);
            form.setValue("unit_price", selectedLine.data.unit_price);
            form.setValue("discount", selectedLine.data.discount);
            form.setValue("tax", selectedLine.data.tax);
        }
    }, [isOpen]);

    // Helper function to handle the selection of a discount
    const handleDiscountSelect = (value: DiscountOption): void => {
        // Check if the clicked discount is already selected (i.e. the user wants to deselect it)
        if (value.id === selectedDiscount?.id) {
            setSelectedDiscount(undefined);
            form.setValue("discount", undefined);
            form.setValue("unit_price", undefined);
            form.setValue("quantity", form.getValues("quantity"));
        } else {
            // Set the selected discount and update the form values
            setSelectedDiscount(value);
            form.setValue("discount", value.discount);
            form.setValue("unit_price", value.salePrice);
            form.setValue("quantity", value.min_quantity ?? form.getValues("quantity"));
        }
    };

    // Helper function to check if the current date is within the valid date range of the discount using dayjs
    function isValidDiscountDate(valid_from: dayjs.Dayjs | undefined, valid_to: dayjs.Dayjs | undefined, currentDate: dayjs.Dayjs) {
        const startDate = valid_from ? dayjs(valid_from) : null;
        const endDate = valid_to ? dayjs(valid_to) : null;

        return (!startDate || currentDate?.isAfter(startDate) && (!endDate || currentDate?.isBefore(endDate)));
    }

    // Helper function to determine the priority discount option
    function determinePriorityDiscount(discountOptions: DiscountOption[]) {
        if (!discountOptions.length) return null;

        // Select the first option as the default
        return discountOptions[0];
    }

    useEffect(() => {
        if (selectedLine) return;
        // If the selected product or customer changes, update the discount options
        // Basically, we set the discount based on the next ierarchy of priority(From highest to lowest):
        // 1. Prices & Discounts
        // 2. General Customer Discount(Settings tab)
        // 3. Customer Groups
        const today = dayjs();
        let discountOptions: DiscountOption[] = [];
        const groupDiscountOptions: DiscountOption[] = [];

        // Fetch prices & discounts if available for the selected product
        // Checks if there's a discount directly associated with the selected product
        const productDiscountInfo = selectedCustomer?.products?.find(p => p.product?.id === selectedProduct?.id);
        const productDiscount = productDiscountInfo &&
        isValidDiscountDate(productDiscountInfo.valid_from, productDiscountInfo.valid_to, today) ? productDiscountInfo : undefined;

        // If there's a discount directly associated with the selected product, add it to the discount options
        if (productDiscount) {
            discountOptions.push({
                id: "productDiscount",
                discount: productDiscount.discount ?? 0,
                salePrice: normalizePrice(productDiscount.sale_price),
                calculated_price: productDiscount.discount && normalizePrice(productDiscount.sale_price) * (1 - productDiscount.discount / 100),
                min_quantity: productDiscountInfo?.min_quantity ?? undefined,
                valid_from: productDiscount.valid_from,
                valid_to: productDiscount.valid_to
            });
        }

        // If the customer has a general discount, add it to the discount options
        if (selectedCustomer?.discount) {
            discountOptions.push({
                id: "customerDiscount",
                discount: selectedCustomer.discount,
                salePrice: normalizePrice(selectedProduct?.prices?.selling_price),
                calculated_price: normalizePrice(selectedProduct?.prices?.selling_price) * (1 - selectedCustomer.discount / 100),
                min_quantity: undefined
            });
        }

        // If the customer has a group discount, add it to the discount options
        selectedCustomer?.groups?.forEach(group => {
            group.products?.filter(product => product.product?.id === selectedProduct?.id).forEach(product => {
                if (isValidDiscountDate(product.valid_from, product.valid_to, today)) {
                    groupDiscountOptions.push({
                        id: `groupDiscount-${group.id}`,
                        name: `${group.name}`,
                        discount: group.discount,
                        salePrice: normalizePrice(product.sale_price),
                        calculated_price: normalizePrice(product.sale_price) * (1 - group.discount / 100),
                        min_quantity: undefined,
                        valid_from: product.valid_from,
                        valid_to: product.valid_to
                    });
                }
            });
        });

        // Sort the group discounts by price
        groupDiscountOptions.sort((a, b) => (a.calculated_price ?? 0) - (b.calculated_price ?? 0));

        // Merge sorted group discounts back into the main discountOptions array
        // This is done to ensure that the group discounts are sorted by price and are displayed first
        discountOptions = [...discountOptions, ...groupDiscountOptions];

        // Determine the pre-selected option based on priority
        const selectedDiscountOption = determinePriorityDiscount(discountOptions);

        // If there's a selected discount option, update the form values
        if (selectedDiscountOption) {
            form.setValue("discount", selectedDiscountOption.discount);
            form.setValue("unit_price", selectedDiscountOption.salePrice);
            form.setValue("quantity", selectedDiscountOption.min_quantity ?? form.getValues("quantity"));
        } else {
            // If there's no selected discount option, reset the form values
            form.setValue("discount", undefined);
            form.setValue("unit_price", selectedProduct?.prices?.selling_price);
            form.setValue("quantity", form.getValues("quantity"));
        }

        // Update component state with the finalized list of discount options and the pre-selected discount
        setDiscountOptions(discountOptions);
        setSelectedDiscount(selectedDiscountOption ?? undefined);
    }, [selectedProduct, selectedCustomer]);

    const onSubmit = form.handleSubmit(data => {
        setIsExtendedProductGetLoading(true);
        dispatch(productsApi.endpoints.getModuleProduct.initiate({module: "sale", id: data.product.id}))
            .unwrap()
            .then(product => {
                const locations = product.locations ?? [];
                const inStock = locations.reduce((acc, curr) => acc + (curr.in_stock ?? 0), 0);

                onLineMutation({
                    ...data,
                    in_stock: inStock,
                    min_sale_quantity: product.min_sale_quantity
                });

                form.reset();
            }).catch(e => {
                onLineMutation(data);
            if (isErrorWithMessage(e)) {
                toast.error(e.message);
            } else {
                toast.error("responses.errorExtendedLoad");
            }
        }).finally(() => {
            setIsExtendedProductGetLoading(false);
        });
    });

    return (
        <>
            <BaseModal
                isOpen={isOpen}
                onClose={onClose}
                isLoading={isExtendedProductGetLoading || isSelectedCustomerLoading}
                width={900}
                padding="56px">
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="modal-title">{selectedLine ? selectedLine.data?.product.name : t("heading")}</div>
                    <BaseInputsGrid cols={2}>
                        <Controller
                            name="product"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInfiniteLoader
                                    fetch={(search, page, limit) => {
                                        setIsProductLazyLoadingFetching(true);
                                        dispatch(productsApi.endpoints.getModuleProductsFull.initiate(
                                                {
                                                    filters: {
                                                        search
                                                    },
                                                    pagination: {
                                                        page,
                                                        limit
                                                    },
                                                    module: "sale"
                                                },
                                                {
                                                    subscribe: false
                                                }
                                            )
                                        ).unwrap()
                                            .then(res => {
                                                setProductsOptions(res?.payload ?? []);
                                            })
                                            .catch(e => {
                                                if (isErrorWithMessage(e)) {
                                                    toast.error(e.message);
                                                } else {
                                                    toast.error(i18n.t("general.responses.somethingWentWrong"));
                                                }

                                                console.error("An error occurred in the products lazy loader", e);
                                            })
                                            .finally(() => {
                                                setIsProductLazyLoadingFetching(false);
                                            });
                                    }}
                                    limit={100}
                                    result={productsOptions}
                                    isLoading={isProductLazyLoadingFetching}
                                    skip={!!selectedLine}
                                >
                                    {
                                        (infinity) => (
                                            <>
                                                <BaseDropdown
                                                    {...field}
                                                    {...fieldState}

                                                    {...adaptInfiniteLoader(infinity)}
                                                    label={t("fields.product.label") + " *"}
                                                    placeholder={t("fields.product.placeholder")}
                                                    getter={{
                                                        label: opt => opt.name,
                                                        key: opt => opt.id,
                                                        caption: opt => opt.code
                                                    }}
                                                    disabled={!!selectedLine}
                                                    virtualize

                                                />
                                            </>
                                        )
                                    }
                                </BaseInfiniteLoader>
                            )}
                        />

                        <Controller
                            name="quantity"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.quantity.label")}
                                    placeholder={t("fields.quantity.placeholder")}
                                />
                            )}
                        />

                        <Controller
                            name="discount"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.discount.label")}
                                    placeholder={t("fields.discount.placeholder")}
                                    type="number"
                                    icon={{ right: { el: <PercentageIcon />, offset: "38px" } }}
                                />
                            )}
                        />

                        <Controller
                            name="tax"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseDropdown
                                    {...field}
                                    error={fieldState.error}

                                    label={t("fields.tax.label")}
                                    placeholder={t("fields.tax.placeholder")}
                                    options={taxesOptions}
                                    getter={{
                                        key: (opt) => opt.id,
                                        label: (opt) => `${opt.rate}%`,
                                        caption: (opt) => opt.name ?? ""
                                    }}
                                    isLoading={isTaxesOptionsLoading}
                                />

                            )}
                        />

                        <Controller
                            name="unit_price"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.unitPrice.label")}
                                    placeholder={t("fields.unitPrice.placeholder")}
                                    type="number"
                                    step="any"
                                    icon={{ 
                                        right: 
                                        { el: 
                                            <span className="w-max h-max text-purple-400 font-thin">{baseCurrency}</span>, 
                                            offset: "38px" 
                                        } 
                                    }}
                                />
                            )}
                        />

                        <Controller
                            name="comment"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="flex flex-col">
                                    <Stack direction={"row"} alignItems={"center"} spacing={1} className={"pointer-events-auto"}>
                                        <BaseLabel>{t("fields.comment.label")}</BaseLabel>
                                        <BaseTooltip title={t("fields.comment.whatIsComment") + ""} placement={"top"}>
                                            <HelpCircle className="mb-2" size={16} color={"#686868"} />
                                        </BaseTooltip>
                                    </Stack>
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        placeholder={t("fields.comment.placeholder")}
                                    />
                                </div>
                            )}
                        />
                    </BaseInputsGrid>
                    {
                        selectedProduct && selectedCustomer && (
                            <Fade in={!!selectedProduct} timeout={500} key={selectedProduct.id}>
                                <div>
                                    <DiscountCard
                                        discounts={discountOptions}
                                        onDiscountSelect={handleDiscountSelect}
                                        selectedDiscount={selectedDiscount}
                                    />
                                </div>
                            </Fade>
                        )
                    }
                    <BaseButton
                        text={selectedLine ? "Save" : t("buttons.add")}
                        size="md"
                        className="w-full"
                    />
                </form>
            </BaseModal>
        </>
    );
}