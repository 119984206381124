import { Stock } from "@/types/general";
import {
    ExportRequest,
    flattenExportRequest,
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse
} from "@redux/api/internalApiSlice";

export const stockApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getStocksFull: builder.query<
            PaginationResponse<Stock.Root>,
            PaginationRequest<Stock.DTO.Filters, Stock.DTO.OrderBy> & {}
        >({
            query: (req) => ({
                url: "stock",
                method: "GET",
                params: flattenPaginationRequest(req)
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        // Adding all products and their variants to the cache
                        ...(result.payload.flatMap((product) => [
                            { type: "Product", id: product.id },
                            ...(product.product_locations?.map((location) => ({
                                type: "Location",
                                id: location.location_id
                            })) ?? []),
                            { type: "Stock", id: product.id }
                        ])),
                        { type: "Stock", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "Stock", id: "PARTIAL-LIST" }]
        }),

        exportStocks: builder.mutation<void, ExportRequest<Stock.DTO.ExportArguments>>({
            query: (args) => ({
                url: `stock/export`,
                responseType: "blob",
                method: "POST",
                data: flattenExportRequest(args)
            })
        })

    })
});

export const {
    useGetStocksFullQuery,
    useExportStocksMutation
} = stockApi;
