import React from "react";

import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useTranslation } from "react-i18next";
import { useCreatePurchaseOrderMutation } from "@redux/features/purchases/purchasesApi";
import { removeEmpty, toastError } from "@helpers/utils";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import MutationLayout from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function PurchaseCreationPage() {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.mutations.creation" });

    const history = useHistory();

    const [createPurchaseOrder, { isLoading: isCreationLoading }] = useCreatePurchaseOrderMutation();

    return (
        <>
            <Can not I="create" a="purchase_order">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>
            
            <Can I="create" a="purchase_order">
                <BaseLoadingBlocker active={isCreationLoading}>
                    <div className="levitation-extended space-y-[40px]">
                        <div className="flex items-center">
                            <h6 className="text-accent grow">{t("heading")}</h6>
                            <div id={"reference_number"}></div>
                        </div>
                        <MutationLayout
                            purchaseOrder={undefined}
                            onSubmit={data => {
                                createPurchaseOrder(removeEmpty({
                                    reference_number: data.reference_number.code,

                                    supplier: data.supplier.id,
                                    purchase_date: data.purchase_date.format("YYYY-MM-DD"),
                                    preferred_delivery_date: data.preferred_delivery_date?.format("YYYY-MM-DD"),
                                    our_reference: data.our_reference?.id,
                                    their_reference: data.their_reference?.id,
                                    payment_terms: data.payment_terms?.id,
                                    delivery_terms: data.delivery_terms?.id,
                                    currency: data.currency.id,
                                    language: data.language?.id,

                                    ...(!!data.delivery_address && {
                                        delivery_address: data.delivery_address.id,
                                    }),

                                    is_billing_for_delivery: data.is_billing_for_delivery,
                                    exchange_rate: data.exchange_rate,
                                    shipping_cost: data.shipping_cost,
                                    is_split_by_products: data.is_split_by_products,

                                    lines: data.lines.filter(line => !!line.data).map((line) => ({
                                        product: line.data!.product.id,
                                        quantity: line.data!.quantity,
                                        unit_price: line.data!.unit_price?.original,
                                        discount: line.data?.discount,
                                        tax: line.data?.tax?.id,
                                        ...(line.module && {group: {
                                            id: line.group_id,
                                            module: line.module,
                                            key: line.key
                                        }})
                                    })),

                                    custom_fields: data.custom_fields.filter(x => x.value).map(field => ({
                                        id: field.id,
                                        value: field.value!
                                    }))

                                })).unwrap().then(data => {
                                    toast.success(t("responses.success"))

                                    history.push(`/dashboard/purchasing/${data.id}/details`)
                                }).catch(e => {
                                    toastError(e);
                                });
                            }}
                        />
                    </div>
                </BaseLoadingBlocker>
            </Can>
        </>
    );
}


