import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useWatch } from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import { Fade, Stack, Typography } from "@mui/material";
import { Check, Pin, RefreshCcw, RotateCw } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import { useGetModuleCurrenciesQuery } from "@redux/features/currencies/currenciesApi";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { Currency } from "@/types/general";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { refinements } from "@helpers/refinements";

const maxDecimalRefinement = refinements.maxDecimalPrecision(7);

const rateInputScheme = z.object({
    rate: z.coerce.number().positive().refine(maxDecimalRefinement.refine, maxDecimalRefinement.message)
});

type RateInputSchemeTyping = z.infer<typeof rateInputScheme>;

type ExchangeRateInputProps = {
    currency?: Currency.Root;
    parentRate?: number;
    onRateChange: (rate: number) => void;
    disabled: boolean;
}

export default function ExchangeRateInput({currency, parentRate, onRateChange, disabled}: ExchangeRateInputProps) {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general"});

    const {control, setValue, handleSubmit} = useForm<RateInputSchemeTyping>({
        resolver: zodResolver(rateInputScheme),
        defaultValues: {
            rate: parentRate
        }
    });

    const {data: currenciesOptions} = useGetModuleCurrenciesQuery("purchase-order");

    const baseCurrency = currenciesOptions?.find(c => c.isBase);

    // Warning: after user's input will be presented as "string" in Zod
    const rate = useWatch({
        control,
        name: "rate"
    });

    // Since rate will be presented as string in Zod until validation, we transform it to float
    const floatRate = typeof rate == "number" ? rate : parseFloat(rate ?? "0");

    useEffect(() => {
        setValue("rate", parentRate ?? currency?.rate ?? 0);
    }, [parentRate, currency]);

    const isDifferentFromCurrency = floatRate !== currency?.rate;
    const isSaved = floatRate === parentRate;

    const onSubmit = handleSubmit(data => {
        onRateChange(data.rate);
    });

    return (
        <div>
            <form onSubmit={onSubmit}>
                {/* Custom input label with locked currency warning */}
                <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={1}
                    className="mb-2"
                >
                    <span className="font-semibold text-inputs-label-dim">{t("fields.exchangeRate.label")}</span>

                    <Fade in={!isDifferentFromCurrency && currency?.isFixed}>
                        <div>
                            <BaseTooltip title={t("fields.exchangeRate.lockWarning") + ""}>
                                <div
                                    className="bg-[#D9D6DA] w-[18px] h-[18px] rounded-full flex justify-center items-center transition-all hover:bg-lightGreen-400 cursor-pointer">
                                    <Pin size={12} color={"#686868"}/>
                                </div>
                            </BaseTooltip>
                        </div>
                    </Fade>
                </Stack>

                {/* Exchange rate input */}
                <div className={"relative"}>
                    <Controller
                        name="rate"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                placeholder={t("fields.exchangeRate.placeholder")}

                                sx={{
                                    ...(isDifferentFromCurrency && {
                                            // Adding padding to not intersect with the buttons
                                            ".MuiInput-input": {
                                                paddingRight: "56px"
                                            }
                                        }
                                    )
                                }}

                                disabled={currency?.isBase || disabled}
                            />
                        )}
                    />

                    {
                        !disabled &&
                        (
                            <Stack
                                sx={{position: "absolute", top: "50%", right: "15px", transform: "translateY(-50%)"}}>

                                {/* Showing ability to reset rate if it is already saved, but differ from the currency original rate*/}
                                {isSaved && isDifferentFromCurrency &&
                                    <BaseMaterialIconButton
                                        color={"neutral"}
                                        size={"small"}
                                        type={"button"}
                                        className="group animate-in fade-in fade-out"
                                        onClick={() => {
                                            setValue("rate", currency?.rate ?? 0);
                                        }}
                                        icon={
                                            <RotateCw
                                                width={16}
                                                height={16}
                                                color={"#686868"}
                                                className={"group-hover:animate-spin fill-mode-forwards"}
                                            />
                                        }
                                    />
                                }

                                {/* If value is set, but not saved / applied -> allowing to save it*/}
                                {!isSaved &&
                                    <BaseMaterialIconButton
                                        color={"neutral"}
                                        size={"small"}
                                        type={"submit"}
                                        className="animate-in fade-in fade-out"
                                        icon={
                                            <Check
                                                width={16}
                                                height={16}
                                                color={"#686868"}
                                            />
                                        }
                                    />
                                }
                            </Stack>
                        )
                    }
                </div>
                <Typography variant="caption" display="block" className={"mt-2"}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <span>1 {baseCurrency?.code}</span>
                        <RefreshCcw width={12} height={12} color={"#686868"}/>
                        <span>{parentRate} {currency?.code}</span>
                    </Stack>
                </Typography>
            </form>
        </div>

    )
}