import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@redux/hooks";
import BaseTable from "@reusables/BaseTable";
import { formatPriceWithCurrency, usePagination } from "@helpers/utils";
import Author from "@components/Dashboard/pages/Products/components/Tabs/items/PricesTab/components/Author";
import { useGetProductSalePricesQuery } from "@redux/features/products/productsApi";
import { Pagination } from "@mui/material";
import OriginLink from "@components/Dashboard/pages/Products/components/Tabs/items/PricesTab/components/OriginLink";

export default function SalePricesTable({ product_id }: { product_id: number }) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.prices" });

    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency);

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data: salePrices, isLoading: isSalePricesLoading } = useGetProductSalePricesQuery({
        product_id,
        pagination
    });

    return (
        <div className={"flex flex-column border border-solid rounded-[8px] p-[8px]"}>
            <div className={"grow"}>
                <BaseTable
                    isDataLoading={isSalePricesLoading}
                    data={salePrices?.payload ?? []}
                    columns={[
                        {
                            header: t("table.columns.sellingPrice"),
                            getter: (row) => (
                                <>
                                    {formatPriceWithCurrency(row.price, baseCurrency)}
                                </>
                            )
                        },
                        {
                            header: t("table.columns.date"),
                            getter: (row) => <>{row.date.format("DD.MM.YYYY HH:mm")}</>
                        },
                        {
                            header: t("table.columns.author"),
                            getter: (row) => {
                                if (row.author) {
                                    return <Author {...row.author} />;
                                }

                                return <span className={"text-[#B1B2E1]"}>
                                        -
                                    </span>;
                            }
                        },
                        {
                            header: t("table.columns.origin"),
                            getter: (row) => <OriginLink origin={row.origin} />
                        }
                    ]}
                />
            </div>

            <Pagination
                className="mt-[32px]"
                {...pagination.adapt(salePrices)}
            />
        </div>

    );
}