// DONE BY DART
const translationEN = {
  navbar: {
    home: "Hjem",
    product: "Produkt",
    pricing: "Prissetting",
    partners: "Partnere",
    about_us: "Om oss",
    support: "Support",
    news: "Nyheter",
    login: "Logg Inn",
  },
  footer: {
    desc: `
            Strømlinjeform ditt lager med skybaserte Suppli lagerstyringssystem. Hold deg oppdatert og ha full kontroll på lageret ditt slik at kundene dine er fornøyde og du kan selv fokusere på verdiskapning og vekst.
        `,
    copyright: "Copyright 2023, By Suppli",
    company: {
      title: "Firmanavn",
    },
    product: {
      title: "Produkt",
      links: {
        1: "Start gratis prøveperiode",
        2: "FAQ",
      },
    },
    followUs: "Følg oss",
  },
  homePage: {
    welcomeBlock: {
      main: "Få full kontroll på lageret ditt  ",
      desc: "Med Suppli vil du ha full kontroll over dine varer, innkjøp, salgsordre, leveringer, og varemottak.",
      btnText: "Kom igang",
    },
    whyUsBlock: {
      preTitle: "Hvorfor velge oss?",
      title: "Fordeler med et lagerstyringssystem",
      cards: {
        organize: {
          main: "Organiser",
          desc: "Organiser og hold orden på inventaret på en enkel måte ",
        },
        reports: {
          main: "Rapporter",
          desc: "Gå aldri tom for varer ved hjelp av advarsler og rapporter",
        },
        manageSales: {
          main: "Organiser salg",
          desc: "Organiser salget ditt med et smartere lagerstyringssystem",
        },
        control: {
          main: "Kontroll",
          desc: "Total kontroll og synlighet på lageret i real-tid",
        },
        integrations: {
          main: "Integrasjoner",
          desc: "Integrasjoner med regnskapssystemet, nettbutikken, og fraktssystemer",
        },
      },
    },
    productBlock: {
      preTitle: "Suppli",
      title: "Produkt",
      descriptions: {
        1: `
                    er et skybasert lagersystem som gir deg full kontroll over produkter, 
					innkjøp,
					salgsordrer, levering, lagerflyt og varetelling.
                     .
                `,
        2: `
                    Suppli optimerer ditt lager slik at du får full oversikt over avgjørende
					faktorer som lager, leveringsplaner og ordrestørrelser..
                `,
        3: `
                    Logger all aktivitet på produktene dine, slik at det er enkelt å se hele 
					historien til hver vare.. For
                    eksempel, når ble det solgt sist, om det har blitt flyttet til en annen 
                    lokasjon, brekkasje, om det er noen ordre og om det har bidratt til endringer i systemet og hvem som har gjort disse endringene.
                `,
        4: `
                    kan integreres med forskjellige systemer og kommuniserer i real tid
                    . Denne kommunkasjonen gjør at systemet oppdateres med lagerverdi,
					fakturagrunnlag, online lagerstatus og automatisk webordrehenting.                    
                `,
      },
      productFooter: {
        btnMain: "Se mer",
        videoBtn: "Se Video",
      },
    },
    pricingBlock: {
      preTitle: "Kom igang raskt og enkelt",
      title: "Priser",
      desc: `
                For å kunne bruke Suppli som en kunde trenger du en obligatorisk startpakke 
				, en premium lisens, og en av brukerstøtte pakkene for å kunne være oppe å gå.
                
            `,
      pricingItems: {
        1: {
          title: "Start pakke",
          features: {
            1: "Suppli programvare",
            2: "3 timers nettbasert opplæring av ansatte i bruk av Suppli*",
            3: "Oppsett av Suppli-konto",
            4: "Importer produkter, kunder og leverandører",
            5: "Oppsett av integrasjoner",
          },
          price: "Kr 15 000, -",
          btn: "Kjøp nå",
          footnotes: {
            1: `
                        *Hvis du vil at vi skal komme til ditt selskap og ha opplæring i 
                        bruken av Suppli, kontakt oss for priser.
                        `,
            2: `
                            **Opptil tre import filer. Filene må være i korrekt format. Vennligst se vår eksempel mal.
                        `,
            3: `
                            ***Opptil tre integrasjoner. Integrasjonen må være i vår portefølje.
                        `,
          },
        },
        2: {
          title: "Lisens",
          premium: {
            title: "Premium", // TODO: +
            features: {
              1: "Full tilgang til programmet",
            },
            price: "Kr 990/Måned pr bruker",
          },
          warehouse: {
            title: "Lager",
            features: {
              1: `
                                Tilgang til levering, lagerflyt, forsendelse, varelager telling og 
                                produktskanning.
                            `,
            },
            price: "Kr 490/Måned pr bruker",
          },
          accounting: {
            title: "Regnskap",
            features: {
              1: `
                                Tilgang til lagerverdi, salgsstatistikk, og 
                                varelagertelling
                            `,
            },
            price: "Kr 290/Måned pr bruker",
          },
          businessP1: {
            title: "Bedriftspakke 1",
            features: {
              1: "5 premium lisenser, 5 lager lisenser, 1 regnskap",
            },
            price: "Kr 4990/Måned",
          },
          businessP2: {
            title: "Bedriftspakke 2",
            features: {
              1: "10 premium lisenser, 10 lager lisenser, 3 regnskap",
            },
            price: "Kr 9990/Måned",
          },
          btn: "Kjøp nå",
        },
        3: {
          title: "Support",
          gold: {
            title: "Gull (alt inkludert)",
            features: {
              1: "10 timers telefonstøtte månedlig.",
              2: "Hjelp med import av produkter, kunder og leverandører.",
              3: "Hjelp med prisjusteringer.",
              4: "Ekstra treningsøkter i bruk av Suppli.",
              5: "Gratis e-post og chat-støtte.",
            },
            price: "Kr 2999/Måned",
          },
          silver: {
            title: "Sølv",
            features: {
              1: "3 timers telefonstøtte månedlig.",
              2: "Gratis e-post og chat-støtte",
            },
            price: "Kr 1499/Måned",
          },
          bronze: {
            title: "Bronse",
            features: {
              1: "1 time telefonstøtte månedlig",
              2: "Gratis e-post og chat-støtte",
            },
            price: "Kr 999/Måned",
          },
          btn: "Kjøp nå",
          footnotes: {
            1: `
                            *Import filer må være i korrekt format. Se vår eksempel fil. Arbeidstimer knyttet til import av filer blir trukket fra de inkluderte support timene i pakken din.
                        `,
          },
        },
      },
      note: "*Alle priser er ekskl. mva",
      footerTexts: {
        1: `
                    Hvis du som kunde vil at vi skal komme til deg for å gjøre konsulent
                    arbeid eller hvis du trenger hjelp til å planlegge og tilpasse en lagerplan for din virksomhet, vennligst kontakt oss..
                `,
        2: `
                    Vi er et selskap lokalisert i Larvik og vi vil fakturere reiser kostnader som hotellovernatting,
					drivstoffutgifter, flybilletter og konsulenttimer.
					Merk at vi fakturerer halv pris for konsulenttimer for slike avtaler. Kontakt oss for priser..
                `,
        3: `
                    Vi har også alt utstyr du trenger for lagerstyring som skannere, bærbare datamaskiner,
					mobile enheter, Ipads, skrivere, strekkodeskannere, RFID-skannere..
                `,
      },
    },
    pricingBlockUSD: {
      preTitle: "Kom igang raskt og enkelt",
      title: "Priser",

      desc: `
                For å kunne bruke Suppli som en kunde trenger du en obligatorisk startpakke 
				, en premium lisens, og en av brukerstøtte pakkene for å kunne være oppe å gå.
				
            `,
      pricingItems: {
        1: {
          title: "Start pakke",
          features: {
            1: "Suppli programvare",
            2: "3 timers nettbasert opplæring av ansatte i bruk av Suppli*",
            3: "Oppsett av Suppli-konto",
            4: "Importer produkter, kunder og leverandører",
            5: "Oppsett av integrasjoner",
          },
          price: "USD 99, -",
          btn: "Kjøp nå",
          footnotes: {
            1: `
                        *Hvis du vil at vi skal komme til ditt selskap og ha opplæring i 
                        bruken av Suppli, kontakt oss for priser.
                        `,
            2: `
                            ** Opptil tre import filer. Filene må være i korrekt format. Vennligst se vår eksempel mal.
                        `,
            3: `
                            ***Opptil tre integrasjoner. Integrasjonen må være i vår portefølje.
                        `,
          },
        },
        2: {
          title: "Lisens",
          premium: {
            title: "Premium",
            features: {
              1: "Full tilgang til programmet",
            },
            price: "USD 99/Måned pr bruker",
          },
          warehouse: {
            title: "Lager",
            features: {
              1: `
                                Tilgang til levering, lagerflyt, forsendelse, varelager telling og 
                                produktskanning.
                            `,
            },
            price: "USD 49/Måned pr bruker",
          },
          accounting: {
            title: "Regnskap",
            features: {
              1: `
                                Tilgang til lagerverdi, salgsstatistikk, og 
                                varelagertelling
                            `,
            },
            price: "USD 29/Måned pr bruker",
          },
          businessP1: {
            title: "Bedriftspakke 1",
            features: {
              1: "5 premium lisenser, 5 lager lisenser, 1 regnskap",
            },
            price: "USD 499/Måned",
          },
          businessP2: {
            title: "Bedriftspakke 2",
            features: {
              1: "10 premium lisenser, 10 lager lisenser, 3 regnskap",
            },
            price: "USD 999/Måned",
          },
          btn: "Kjøp nå",
        },
        3: {
          title: "Support", // TODO: +
          gold: {
            title: "Gull (alt inkludert)",
            features: {
              1: "10 timers telefonstøtte månedlig.",
              2: "Hjelp med import av produkter, kunder og leverandører.",
              3: "Hjelp med prisjusteringer.",
              4: "Ekstra treningsøkter i bruk av Suppli.",
              5: "Gratis e-post og chat-støtte.",
            },
            price: "USD 285/Måned",
          },
          silver: {
            title: "Sølv",
            features: {
              1: "3 timers telefonstøtte månedlig.",
              2: "Gratis e-post og chat-støtte",
            },
            price: "USD 142/Måned",
          },
          bronze: {
            title: "Bronse",
            features: {
              1: "1 time telefonstøtte månedlig",
              2: "Gratis e-post og chat-støtte",
            },
            price: "USD 96/Måned",
          },
          btn: "Kjøp nå",
          footnotes: {
            1: `
                            *Import filer må være i korrekt format. Se vår eksempel fil. Arbeidstimer knyttet til import av filer blir trukket fra de inkluderte support timene i pakken din.
                        `,
          },
        },
      },
      note: "*Alle priser er ekskl. MVA",
      footerTexts: {
        1: `
                   Hvis du som kunde vil at vi skal komme til deg for å gjøre konsulent
                    arbeid eller hvis du trenger hjelp til å planlegge og tilpasse en lagerplan for din virksomhet, vennligst kontakt oss. 
                
                `,
        2: `
                    Vi er et selskap lokalisert i Larvik og vi vil fakturere reiser kostnader som hotellovernatting,
					drivstoffutgifter, flybilletter og konsulenttimer.
					Merk at vi fakturerer halv pris for konsulenttimer for slike avtaler. Kontakt oss for priser.
                `,
        3: `
                    Vi har også alt utstyr du trenger for lagerstyring som skannere, bærbare datamaskiner,
					mobile enheter, Ipads, skrivere, strekkodeskannere, RFID-skannere. 
                `,
      },
    },
    aboutBlock: {
      preTitle: "Ansatte",
      title: "Om oss",
      descriptions: {
        1: `
                   Suppli AS er et nytt norsk selskap som ble etablert i 2021.
				   Vårt hovedformål er å gi våre kunder de beste løsninger som
				   hjelper dem å løse problemer med lager ledelse og utvikling.
                `,
        2: `
                    Vi holder til i Elveveien 28 i Larvik, kun få minutter vekk fra E18 og jernbanestasjonen.
					Det beste vi vet er å få besøk av våre kunder der vi er mer enn tar deg gjerne med inn i 
					demorommet vårt.Her kan du teste maskinvare og se løsningene våre live. 
					Vår filosofi er det samspillet mellom mennesker og teknologi bør harmonisere. 
					Derfor har vi valgt de samarbeidspartnerne vi har i dag.
					De er fremtidsrettede, yter enestående service og har produkter som
					hjelper våre kunder med effektivitet og fortjeneste. 
					
					Vi ser frem til å samarbeide med deg, så kontakt oss for en uforpliktende chat
                    
                   
                `,
        3: `
                    .
                `, // TODO: +
      },
      team: {
        1: {
          position: "Utviklingssjef",
        },
        2: {
          position: "KAM Norden",
        },
        3: {
          position: "CEO",
        },
        4: {
          position: "CMO",
        },
        5: {
          position: "CFO",
        },
      },
    },
    supportBlock: {
      title: "Support",
      text1: `
                Vårt support team er alltid klare for å hjelpe deg med dine problemer eller spørsmål.
            `,
      text2: "For support contakt oss på telefon",
      text3: "eller support mail",
      text4: `
                Du kan alltid bruke support chaten mens du er logget inn i systemet.
                
            `,
      postalAddress: `
                Post addresse
            `,
      info1: "Suppli AS",
      info2: "Elveveien 28",
      info3: "3262 Larvik",
      info4: "Norge",

      text5: "SUPPORT ÅPNINGSTIDER",
      text6: "Mandag - Fredag",
      text7: "Lørdag - Søndag",
      text8: `
                Stengt (utenfor
                åpningstider, du vil bli fakturert Kr 1999/time
                uavhengig av avtale)
            `,
    },
  },
  productPage: {
    productsBlock: {
      header: "Suppli Produkter",
      desc: "Jobb smartere og tilfredstill alle din bedrifts behov med vårt brukervennlige og intuitive lagerstyringssystem",
    },
    productDesc: {
      1: {
        header: "Full kontroll",
        description:
          "Suppli er et skybasert lagersystem som gir deg full kontroll over produkter, innkjøp, salgsordrer, levering, lagerflyt og varetelling. Siden systemet er skybasert, kan du jobbe fra hvor som helst og på hvilken som helst enhet.",
      },
      2: {
        header: "Ordrestyring",
        description:
          "Hold styr på alle bestillingene dine. Suppli optimerer ditt lager slik at du får full oversikt over avgjørende faktorer som lager, leveringsplaner og ordrestørrelser.",
      },
      3: {
        header: "Inventar",
        description:
          "Logger all aktivitet på produktene dine, slik at det er enkelt å se hele historien til hver vare. Salgsstatistikk, plasseringsbevegelser, brudd, nye bestillinger og en hel masse andre funksjoner.",
      },
      4: {
        header: "Integrasjoner",
        description:
          "Suppli elsker alle, så selvfølgelig har vi en åpen API. Vi har allerede integrasjoner med flere forskjellige systemer som nettbutikker og regnskapsprogramvare slik at du slipper å manuelt oppdatere lagerverdi, fakturagrunnlag, online lagerstatus og automatisk webordrehenting. ",
      },
    },
  },
  pricingPage: {
    getStartedBlock: {
      title: {
        1: "Kom igang nå,",
        2: "Velg en plan",
      },
      description:
        "For å kunne bruke Suppli som en kunde trenger du en obligatorisk startpakke , en premium lisens, og en av brukerstøtte pakkene for å kunne være oppe å gå.",
    },
    pricingItems: {
      1: {
        title: "Start pakke",
        features: {
          1: "Suppli programvare",
          2: "3 timers nettbasert opplæring av ansatte i bruk av Suppli*",
          3: "Oppsett av Suppli-konto",
          4: "Importer produkter, kunder og leverandører",
          5: "Oppsett av integrasjoner",
        },
        price: "Kr 15 000, -",
        btn: "Kjøp nå",
        footnote: {
          1: `
                        *Hvis du vil at vi skal komme til ditt selskap og ha opplæring i 
                        bruken av Suppli, kontakt oss for priser.
                        `,
          2: `
                            ** Opptil tre import filer. Filene må være i korrekt format. Vennligst se vår eksempel mal.
                        `,
          3: `
                            ***Opptil tre integrasjoner. Integrasjonen må være i vår portefølje.
                    `,
        },
      },
      2: {
        title: "Lisens",
        premium: {
          title: "Premium",
          features: {
            1: "Full tilgang til programmet",
          },
          price: "Kr 990",
          interval: "Måned pr bruker",
        },
        warehouse: {
          title: "Lager",
          features: {
            1: `
                           Tilgang til levering, lagerflyt, forsendelse, varelager telling og 
                                produktskanning
                        `,
          },
          price: "Kr 490",
          interval: "Måned pr bruker",
        },
        accounting: {
          title: "Regnskap",
          features: {
            1: `
                            Tilgang til lagerverdi, salgsstatistikk, og 
                                varelagertelling
                        `,
          },
          price: "Kr 290",
          interval: "Måned pr bruker",
        },
        businessP1: {
          title: "Bedriftspakke 1",
          features: {
            1: "5 premium lisenser, 5 lager lisenser, 1 regnskap",
          },
          price: "Kr 4990",
          interval: "Måned",
        },
        businessP2: {
          title: "Bedriftspakke 2",
          features: {
            1: "10 premium lisenser, 10 lager lisenser, 3 regnskap",
          },
          price: "Kr 9990",
          interval: "Måned",
        },
        btn: "Kjøp nå",
      },
      3: {
        title: "Support",
        gold: {
          title: "Gull (alt inkludert)",
          features: {
            1: "10 timers telefonstøtte månedlig.",
            2: "Hjelp med import av produkter, kunder og leverandører.",
            3: "Hjelp med prisjusteringer.",
            4: "Ekstra treningsøkter i bruk av Suppli.",
            5: "Gratis e-post og chat-støtte.",
          },
          price: "Kr 2999",
          interval: "Måned",
        },
        silver: {
          title: "Sølv",
          features: {
            1: "3 timers telefonstøtte månedlig.",
            2: "Gratis e-post og chat-støtte",
          },
          price: "Kr 1499",
          interval: "Måned",
        },
        bronze: {
          title: "Bronse",
          features: {
            1: "1 time telefonstøtte månedlig.",
            2: "Gratis e-post og chat-støtte",
          },
          price: "Kr 999",
          interval: "Måned",
        },
        btn: "Kjøp nå",
        footnote: `
                    *Import filer må være i korrekt format. Se vår eksempel fil. Arbeidstimer knyttet til import av filer blir trukket fra de inkluderte support timene i pakken din.
                `,
      },
    },
    attendanceBlock: {
      preTitle: "Trenger du hjelp?",
      title: "Deltakelse",
      description:
        "Vi snakker gjerne med deg om hvilket som helst spørsmål eller saker for å kunne hjelpe deg med å jobbe raskere, og utvikle raskere.",
      card: {
        1: {
          title: "Kontakt oss",
          desc: "Eksisterende kunder kan ta kontakt med oss hvis de har behov for rådgivning med planlegging og tilpasning av lageret for deres bedrift.",
        },
        2: {
          title: "Konsultasjonstimer",
          desc: "Vi er et selskap lokalisert i Larvik og vi vil fakturere reise kostnader som hotellovernatting,drivstoffutgifter, flybilletter og konsulenttimer.Merk at vi fakturerer halv pris for konsulenttimer for slike avtaler. Kontakt oss for priser",
        },
      },
    },
    equipmentBlock: {
      preTitle: "Hold deg oppdatert med innovasjon",
      title: "Utstyr",
      description:
        "Vi kan levere alt utstyret du trenger for lagerstyring som skannere, bærbare datamaskiner, mobile enheter, Ipads, skrivere, strekkodeskannere, RFID-skannere",
      items: [
        {
          header: "EA520",
          description: "Robust smarttelefon",
          longDesc: `Ved å introdusere EA520, leverer den banebrytende, robuste smarttelefonen på 5 tommer kraftig strekkodelesing, praktisk tilkobling, holdbar, men den lette designen som akkurat passer i håndflaten. Dette forbedrer servicenivået for ulike vertikale applikasjoner innen detaljhandel, gjestfrihet og felttjenester.`,
          features: [
            "5-tommers skjerm med 450 nits sollys lesbar",
            "Android 11 OS med GMS-sertifisering",
            "Støtter de fleste 1D / 2D-strekkoder på mobilskjermer, til og med DPM-strekkoder",
            "Lettvektsdesign som akkurat passer i håndflaten.",
            "Alt-i-ett-funksjonalitet: HF/NFC og 13 MP-kamera",
            "Robust: Corning® Gorilla® Glass 3 berøringsskjerm, IP67-klassifisert og 1,5 M fall (1,8 M med støvelveske)",
            "Langvarig avtakbart 4100mAh batteri",
            "Støtter Bluetooth® 5 / dual-band WLAN, rask roaming / 4G LTE",
            "Støtter MDM-programvareløsninger og verktøy som MoboLink..",
          ],
          price: "Kr 6990",
        },
        {
          header: "EA630",
          description: "Robust smarttelefon",
          longDesc: `Unitech EA630 er en 6-tommers robust mobil smarttelefon med 80 % skjerm-til-kroppsforhold, med allsidig funksjonalitet med kraftig datainnsamling. EA630 er spesialdesignet for bærbarhet og er kombinert med en kompakt og slitesterk design som gjør den til et ideelt verktøy for å øke effektiviteten for bruk innen detaljhandel, gjestfrihet og lette felttjenester.`,
          features: [
            "6-tommers robust smarttelefon med et skjerm-til-kroppsforhold på over 80 %",
            "Android 10 OS med GMS- og AER-sertifisering",
            "Alt-i-ett-funksjonalitet: 2D-bildekamera, HF/NFC og 16 MP-kamera",
            "Robust: Corning® Gorilla® Glass 6 berøringsskjerm, IP65-klassifisert og 1,2M fall (1,5M med bagasjerom)",
            "Langtlevende avtakbart 4000mAh batteri (opptil 16 timers arbeidstid",
            "Støtter Bluetooth® 5 / dual-band WLAN, fast roaming / 4G LTE",
            "Støtter MDM-programvareløsninger og verktøy som AirWatch, SOTI, Ivanti Wavelink og MoboLink",
          ],
          price: "Kr 8990",
        },
        {
          header: "HT730",
          description: "4-tommers robust håndholdt terminal",
          longDesc: `Unitech HT730 er en ergonomisk enhåndsbetjening håndholdt terminal med tastatur og kraftige datainnsamlingsløsninger. Med Android 10 OS med GMS-sertifisering, er HT730 utstyrt med Qualcomm® Artificial Intelligence (AI) CPU for å gi brukere utmerket ytelse for smartere, mer intuitive interaksjoner som er egnet til å forbedre arbeidseffektiviteten din i en lang rekke applikasjoner innen lager, logistikk , og felttjeneste.`,
          features: [
            "Android 10 OS med GMS-sertifisering",
            "Ergonomisk enhåndsbetjening",
            "Overlegen 2D-skanningsytelse: Støtt 30-graders skanning tilt ned",
            "Overlegen ytelse med skanningsområde opptil 20 meter unna",
            "Støtter IEEE 802.11a/b/g/n, 802.11ax-ready, 802.11ac Wave 2 med 2X2 MU-MIMO WLAN Standard",
            "6700mAh batteri for 20-26 timers drift",
            "Ekte Hot-swap batteridesign uten nedetid",
            "1,8M fallsikkert skall (2,4M med støtfanger)",
            "IP65/IP67 beskyttelse mot vann og støv",
            "Holdbart tastatur med bakgrunnsbelysning: 38/29 taster med 2,5 millioner levetidsklikk",
            "Støtter Bluetooth® 5.1",
            "Støtter MDM-programvare og verktøy som MoboLink, StageGo, Ivanti Wavelink og StayLinked",
          ],
          price: "Kr 17990",
        },

        // has links
        {
          header: "TSP800II",
          description:
            "Multifunksjonell strekkode, etikett, kvittering og etikettskriver",
          longDesc: `TSP800II er en unik og allsidig termisk skriver som kan skrive ut på bredere enn gjennomsnittet media opptil 112 mm bred.  Kombinert med en unik A4-rasterdriver som er i stand til å skalere et A4-bilde til 112 mm bredt papir, kan TSP800II integreres som en kostnadseffektiv kvitterings-, billett- eller etikettskriver eller et brukervennlig og kompakt alternativ til standard A4-faktura- eller rapportskrivere i butikk- og gjestehusmiljøer med betydelige kostnadsbesparelser for papir og forbruksvarer . TSP800II har en svartmerkesensor og kan skrive ut på media opptil 150 g/m2, noe som gjør den til en ideell lavprisetikettskriver for hyllkantetiketter eller en pålitelig billettskriver av høy kvalitet for billetter i bredere format mark.`,
          features: [],
          price: "Kr 7990",
        },
        {
          header: "MS340",
          description: "Håndholdt bildeskanner (1D)",
          longDesc: `MS340 er en langdistanseskanner, noe som betyr at du ikke trenger å ta kontakt med en strekkode for å lese den. Gi datainnsamlingsprosessen din større fleksibilitet og varig holdbarhet.`,
          features: [
            "Langdistanseskanning: opptil 15 tommer",
            "Overlegen skanning: opptil 500 skanninger/sek",
            "Leser alle vanlige 1D-strekkoder inkludert GS1 DataBar og stablede koder",
            "Håndfri stativ tilgjengelig",
            "Tåler 5-fots fall til betong",
            "Grensesnittalternativer: USB, RS232, Keyboard Wedge",
            "Miljøtettet mot fukt og støv",
            "garanti: 5 år",
          ],
          price: "Kr 1290",
        },

        {
          header: "HT730UHF",
          description: "RFID robust håndholdt terminal",
          longDesc: `HT730 UHF er en rundstrålende RFID-terminal med 15M leseområde som leser 750 tagger+ per sekund for å gi kollegaer rask tilgang til data når de håndterer lager. Med den sofistikerte IMPINJ R2000 innebygde brikken er det aldri en plagsom oppgave å få klar synlighet av eiendeler og plassering. Det er ikke nødvendig å slå av enheten, så dine kollegaer kan fortsatt varmebytte 6700mAh batteri ved kontinuerlig arbeid. En ultra-kraftig RFID-leser med motstand mot støv og vann og som tåler 1,8 m fall passer best for lager, feltservice og transport og logistikk.`,
          features: [
            "Bransjeledende 15M RFID leseområde",
            "Høy følsomhet og optimal brikke: IMPINJ R2000",
            "Smartbatteri som kan byttes med varig kapasitet på 6700mAh",
            "Utfør 750+ tag-lesninger per sekund",
            "Maksimal EIRP på 36 dBm",
            ,
            "Støtt Hot-Swap-funksjon, holder applikasjoner aktive i opptil 1 minutt",
            "Integrerte forskjellige WIFI-tilkoblinger: IEEE 802.11a/b/g/n, 802.11ax-ready, 802.11ac Wave 2 med 2X2 MU-MIMO WLAN Standard",
            "Koble trådløst med Bluetooth® 5.1",
            "Ultrasensitiv 4” skjerm berøringsskjerm",
            "Balansert, ergonomisk og robust pistolgrepsdesign",
            "Bedriftsbestandig beskyttelse med IP65-beskyttelse og 1,8 m fall til betong",
          ],
          price: "Kr 21990",
        },
      ],
    },
  },
  supportPage: {
    faqBlock: {
      preTitle: "Vanlige spørsmål",
      title: "Support senter",
      desc: `
                Vi er dedikerte til å tilby fremragende tjenester som garanterer din tilfredshet og suksess. Vi er alltid tilgjengelige og hjelper gjerne til med å gi din bedrift konkurransefortrinnet det krever for å blomstre.
            `,
      article1: {
        boldTitle: "Hvordan få hjelp",
        desc1: `
                    Suppli gir den personlige hjelpen du trenger for å få konkurransefortrinn i din bransje. Uansett hva dine mål, problemer eller behov er, er vi alltid tilgjengelige for en grundig konsultasjon slik at du kan få mest mulig ut av investeringen din.  
                `,
        desc2: `
                    Du er også velkommen til å bruke de gratis ressursene på denne siden for å få svar på spørsmålene dine så raskt som mulig. Hvis du ikke finner svaret du trenger eller ønsker å få en mer detaljert forklaring, ta gjerne kontakt på telefon nummer +47******** eller support mail post@suppli.no. Hvis du allerede er kunde av Suppli, kan du alltids bruke support chatten med en gang du er logget inn. Hos Suppli, har kundens suksess alltid topp prioritet !
                `,
      },
    },
    subscribeText: "Abonner på vårt nyhetsbrev og gå aldri glipp av de nyeste oppdateringene fra Suppli",
    questionsBlock: {
      1: {
        q: `
                    Hve er Suppli WMS (Lagerstyringssystem)? 
                `,
        a: `
                    Suppli er et skybasert lagerstyringssystem som gir deg full kontroll over dine varer, kjøp, salgsordrer, leveranser, lagerbevegelser og lagertellinger. Med Suppli blir lagerstyringen din enklere, rimeligere og mer effektivt.
                `,
      },
      2: {
        q: `
                Hvordan vet jeg at jeg trenger et WMS?
                `,
        a: `
                Hvis du vil ha full kontroll over virksomheten og inventaret og eliminere kostbare lagerfeil,
				trenger du et WMS. Å ha et robust WMS vil hjelpe deg med å optimere lagerplass og automatisere operasjoner,
				få bedre synlighet av inventaret ditt, administrere arbeidskraft mer effektivt, gi bedre kundeservice og skalere raskere.
                `,
      },
      3: {
        q: `
                Hvorfor velge Suppli?
                `,
        a: `
                Suppli tilbyr en innovativ og brukervennlig løsning drevet av den beste teknologiske utviklingen. Dette vil gi deg en sjanse til å overvåke, spore og optimalisere driften din med full kontroll og letthet. Tilgjengelig og lett tilgjengelig på alle enheter, når som helst og hvor som helst.
                `,
      },
      4: {
        q: `
                Hvilke typer selskaper er egnet for Suppli WMS?
                `,
        a: `
                Alle typer selskaper, fra små startups til store selskaper, vil dra nytte av funksjonene til Suppli WMS. Når det gjelder bransjer, fokuserer Suppli på å betjene følgende: detaljhandel, produksjon, engros, elektronikk, farmasøytisk, transport, tredjepartslogistikk (3PL), landbruk, mat og drikke, helse og skjønnhet og mange flere. 
                `,
      },
      5: {
        q: `
                Hvilke funksjoner tilbyr Suppli WMS?
                `,
        a: `
                Målet med Suppli er å sikre at du enkelt kan organisere og holde styr på varelageret ditt i virksomheten din, det være seg innkjøp, salgsordrer eller lagerflyt. Du vil få full oversikt over avgjørende faktorer som lager, leveringsplaner og ordrestørrelser, samt få rapporter og advarsler angående varelageret. Suppli WMS integreres også enkelt med ERP, nettbutikk, økonomi, fraktsystemer m.m.
                `,
      },
      6: {
        q: `
                Hvordan og i hvilke språk kan jeg få support?
                `,
        a: `
                Vi er her for deg 24/7 på telefon, e-post og chat. Primære telefonspråk er engelsk og norsk, men vi kan også tilby støtte på følgende språk: tysk, russisk, litauisk og svensk.
                `,
      },
      7: {
        q: `
                Kan jeg få en demo for å teste Suppli WMS?
                `,
        a: `
                Absolutt! Vi lar deg mer enn gjerne teste ut maskinvaren og se løsningene våre live. For å lære mer om demoen, kontakt oss på telefon, e-post eller chat, eller treff oss personlig i Mellombakken 6 i Larvik.  
                `,
      },
    },
    subscribeBlock: {
      preTitle: "Abonnere",
      title: "Bli med 2000+ abonnenter",
      subscribeTextLeft: "Hold deg oppdatert med alt du trenger å vite.",
      subscribeTextRight1: "Vi bryr oss om dataene dine i vår",
      subscribeTextRight2: "personvernerklæring",
      subscribeBtn: "Abonnere",
      notifyBtn: "Gi meg beskjed",
      sendMessage: "Send Melding"
    },
  },
  loginPage: {
    loginMain: "Velkommen tilbake",
    googleBtn: "Fortsett med Google",
    orLabel: "ELLER",
    emailLabel: "E-post",
    passwordLabel: "Passord",
    rememberLabel: "Husk i 30 dager",
    forgotPasswordLabel: "Glemt passord?",
    logInBtn: "Logg inn",
    noAccountLabel: "Har ikke konto?",
    registerBtn: "Registrer deg",
    whatNewLabel: "Hva er nytt",
  },
  signupPage: {
    signupMain: "Kom igang",
    googleBtn: "Fortsett with Google",
    orLabel: "ELLER",
    emailLabel: "Email",
    passwordLabel: "Passord",
    showPasswordLabel: "Vis passord",
    signupBtn: "Registrer deg",
    alreadyHaveLabel: "Har allerede konto?",
    loginLabel: "Logg inn",
    registerTestemonial: '“Å bytte til Suppli har forbedret lagerdriften vår enormt. Det er den mest brukervennlige og intuitive løsningen jeg noen gang har brukt, og overgår langt alle andre WMS når det gjelder funksjonalitet”',
    clickHereToLogIn: "Klikk her for å logge inn",
    p1: "Administrer lageret",
    p2: "ditt enkelt og nøyaktig.",
    step: "Steg",
    receivePromotions: "Jeg vil gjerne motta markedsføringsinformasjon og nyheter om Suppli.",
    first_name: "Fornavn *",
    last_name: "Etternavn *",
    company_name: "Firmanavn *",
    country: "Land *",
    street: "Gate",
    street2: "Gate 2",
    city: "By",
    zipcode: "Postnummer",
    phone_number: "Telefon",
    email: "E-postadresse *",
    job_role: "Stilling",
    base_currency: "Grunnvaluta",
    // roles: ["Engros eller distribusjon", "Netthandel", "Produksjon eller montering", "Byggeplasser eller arbeidssteder", "Detaljhandel", "Kontorrekvisita eller intern lagerbeholdning", "Annet"],
    // businessTypes: ["Engros eller distribusjon", "Netthandel", "Produksjon eller montering", "Byggeplasser eller arbeidssteder", "Detaljhandel", "Kontorrekvisita eller intern lagerbeholdning", "Annet"],
    // headings: ["Opprett nytt selskap", "Let's get to know you", "What best fits your business?", "Almost ready!"],
    // buttonTitles: ["Fortsett", "Fortsett", "Create company", "Activate account"]
    "BusinessOwner": "Forretningsdriftseier",
    "InventoryOperations": "Varebehandling og operasjoner",
    "ProductionAndManufacturing": "Produksjon og fabrikasjon",
    "PurchasingOrProcurement": "Innkjøp eller anskaffelse",
    "FinanceOrOfficeAdministration": "Økonomi eller kontoradministrasjon",
    "SalesMarketing": "Salg og markedsføring",
    "Consultant": "Konsulent",
    WholesaleOrDistribution: "Engros eller distribusjon",
    Ecommerce: "Netthandel",
    ManufacturingOrAssembly: "Produksjon eller montering",
    ConstructionOrJobsites: "Bygg eller arbeidsplasser",
    Retail: "Detaljhandel",
    OfficeSuppliesOrInternalInventory: "Kontorrekvisita eller intern lagerbeholdning",
    Other: "Annet",
    CreateNewCompany: "Opprett nytt selskap",
    LetsGetToKnowYou: "La oss bli kjent med deg",
    WhatBestFitsYourBusiness: "Hva passer best for din bedrift?",
    AlmostReady: "Nesten klar!",
    "Continue": "Fortsett",
    "CreateCompany": "Opprett selskap",
    "ActivateAccount": "Aktiver konto",
    letsPassword: "La oss opprette passordet ditt",
    termsAgree: "Jeg godtar vilkårene og betingelsene",
    password: "Passord",
    confirmPassword: "Bekreft passord",
  },
  forgotPassword: {
    emailInputPage: {
      stage1: {
        heading: "Glemt passord?",
        subheading:
          "Ikke bekymre deg, vi sender deg tilbakestill instruksjoner",
        button: "Tilbakestill passord",
        return: "Tilbake til innlogging",
      },
      stage2: {
        heading: "Sjekk eposten din",
        subheading: "Vi sendte en lenke til tilbakestilling av passord til",
        didntReceive: "Har du ikke mottatt e-posten? Klikk for å sende på nytt",
        button: "Send e-post igjen",
        return: "Tilbake til innlogging",
      },
    },
    resetPage: {
      stage1: {
        heading: "Sett nytt passord",
        subheading:
          "Ditt nye passord må være forskjellig fra tidligere brukte passord",
        inputs: {
          original: {
            title: "Passord",
            restriction: "Må være på minst 8 tegn",
          },
          confirmation: {
            title: "Bekreft passord",
          },
        },
        action: "Tilbakestille passord",
      },
      stage2: {
        heading: "Nytt passord er lagret",
        subheading: [
          "Passordet ditt har blitt tilbakestilt.",
          "Klikk nedenfor for å logge på.",
        ],
        action: "Logg inn",
      },
    },
  },
  partnersPage: {
    welcomeBlock: {
      heading: "Vi er transformerende når vi er sammen",
      subheading:
        "Løsninger som utgjør en forskjell krever de beste hodene. Det er derfor vi samarbeider med ledende selskaper i ulike bransjer for å dele vår ekspertise, lære av hverandre, og skape innovative produkter som hjelper deg som våre kunder å lykkes.",
      cta_1: "Bli en partner",
      cta_2: "Se Partnere",
    },
    partnersPopup: {
      lets: "La oss samarbeide",
      companyName: "Firmanavn",
      email: "E-post",
      phone: "Telefon",
      contactPerson: "Kontaktperson",
      getStarted: "Kom i gang"
    },
    whyPartners: {
      heading: "Nøkkelen til å gjøre en forskjell",
      subheading:
        "Hos Suppli vet vi at vår suksess kun er mulig gjennom de sterke relasjonene vi har med våre partnere. Med det erfarne og kunnskapsrike fellesskapet av eksperter vi har, kan du være trygg på at alle områder av virksomheten din vil se en forbedring.",
    },
    advantages: {
      first: {
        heading: "Strategiske partnerskap",
        subheading:
          "Våre partnerskap er opprettet med ett mål i tankene: å komme våre kunder til gode. Vi ønsker å hjelpe deg med å nå dine mål, og vi er her for å hjelpe deg hvert steg på veien.",
      },
      second: {
        heading: "Omfattende løsninger",
        subheading:
          "Forhold til innflytelsesrike globale selskaper gjør at vi kan tilby våre kunder et enda bredere spekter av løsninger med mer funksjonalitet.",
      },
      third: {
        heading: "Inngående kunnskap",
        subheading:
          "Med våre partnere får vi tilgang til et bredere spekter av ekspertise, ideer og nye perspektiver på gamle problemer..",
      },
      fourth: {
        heading: "Eksklusive tilbud og verdi",
        subheading:
          "Våre partnerskap hjelper deg med å spare penger og reinvestere tilbake i virksomheten din samtidig som du tjener mer.",
      },
    },
    companies: {
      heading_1: "Partnere",
      heading_2: "Regnskapssførere",
      dropdown: {
        heading: "Partnere etter land",
        option_all: "Alle",
      },
      list: {
        woocommerce: {
          heading: "WooCommerce",
          subheadings: [
            "WooCommerce er en flott e-handelsplattform som lar deg raskt sette opp en nettbutikk og kan tilpasses for å passe de spesifikke behovene til enhver bedrift.",
            "Å integrere WooCommerce-butikken din med Suppli WMS vil bidra til å ta gjettingen ut av lagerkontroll og ordreoppfyllelse ved å automatisk synkronisere produkter i sanntid",
            "Når en kunde legger inn en bestilling, sendes den umiddelbart til lageret hvor den klargjøres og sendes så snart som mulig. Hvis en vare er utsolgt i WMS, vil den vises som ikke på lager på hjemmesiden din - Det betyr ikke flere overraskelser for kundene (eller deg selv!).",
          ],
        },
        hubspot: {
          heading: "Hubspot",
          subheadings: [
            "Hubspot gir en utmerket CRM-plattform for salgsteam, med automatisering, rapportering og salgsforløpsdministrasjonsverktøy.",
            "Å integrere Suppli WMS med Hubspot CRM vil holde bedriften et skritt foran ved å automatisere repeterende manuelle prosesser, forbedre responstidene og gjøre teamet ditt mer effektivt. Dessuten vil du kunne holde styr på hver kundeinteraksjon og bruke disse dataene til å forbedre forretningsprosessene dine. ",
            "De to systemene som samarbeider vil tillate deg å tilby et høyere servicenivå og ta mer informerte beslutninger om virksomheten din.",
          ],
        },
        poweroffice_go: {
          heading: "PowerOffice GO",
          subheadings: [
            "PowerOffice Go er et komplett regnskapssystem som tilbyr alle funksjonene og funksjonaliteten du trenger for å administrere virksomhetens økonomi (salg og utgifter, fakturaer og estimater, rapporter og mer).",
            "Å ha Suppli WMS integrert med PowerOffice GO regnskapsprogramvare er en fin måte å strømlinjeforme driften og unngå menneskelige feil. ",
            'Vi mener at du som kunde ikke trenger å kaste bort tid på å manuelt legge inn informasjon som kunder, produkter, beløp og priser - som alle finnes i WMS. Når du trykker "complete"',
          ],
        },
        rivals: {
          heading: "Rivals Software", //TODO: +
          subheadings: [
            "Rivals Software is an innovative software development company that specializes in developing ready-to-deploy and customized software solutions, as well as state-of-the-art websites. The company serves a wide range of industries, including healthcare, finance, education, and retail, among others.", //TODO: +
            "Rivals Software's mission is to help businesses improve their operations and increase their productivity through the use of cutting-edge software technologies. The company has a team of highly skilled software developers and engineers dedicated to providing effective solutions to businesses of all sizes.", //TODO: +
            "", //TODO: +
          ],
        },
      },
    },
    footerCTA: {
      heading: "Klar til å koble til?",
      subheading: "Vi er spente på å se hvordan vi kan hjelpe deg med å lykkes",
      cta: "Koble til teamet vårt",
    },
  },
  dashboard: {
    sidebar: {
      dashboard: "Dashboard",
      calendar: "Kalender",

      products: "Produkter",
      allProducts: "Alle Produkter",
      productionOrder: "Stykklister",

      purchasing: "Innkjøp",
      purchases: "Innkjøpsordre",
      receive: "Motta",

      inventory: "Inventar",
      quickPurchaseOrder: "Rask kjøpsordre",

      stock: "Lageroversikt",
      adjustments: "Lagerendringer",
      collections: "Samlinger",
      transfers: "Lageroverføring",

      orders: "Ordre",
      placeInOrder: "Plasser i rekkefølge",

      customers: "Kunder",
      newCustomer: "Ny kunde",
      customerList: "Kundeliste",
      customerGroup: "Kundegruppe",

      suppliers: "Leverandører",

      userLogs: "Brukerlogger",
      settings: "Innstillinger",
    },
    navbar: {
      languages: {
        english: "Engelsk",
        norwegian: "Norsk",
      },
      totalRevenue: {
        totalRevenue: "TOTALE INNTEKTER",
        thisMonth: "denne måneden",
      },
      notifications: {
        yourNotifications: "Dine varsler",
      },
      userMenu: {
        myAccount: "Min konto",
        companyInfo: "Firmainformasjon",
        subscription: "Abonnement",
        action: "Logg ut",
      },
    },
    dashboard: {
      stats: {
        revenue: {
          heading: "Inntekter", // auto-uppercase
          chart: {
            expenses: "Utgifter",
            income: "Inntekt",
          },
          tabs: ["Månedlig", "Ukentlig", "Idag"],
        },
        bestSellingProducts: {
          heading: "Bestselgende produkter",
          chip: "denne måned",
        },
        feed: {
          heading: "Oppdateringsflyt",
          columns: ["Dato", "Tid", "Hendelse"],
        },
        restocking: {
          heading: "Restocking",
          columns: ["Produkt", "Stk"],
          orderButton: "Bestill nå",
        },
      },
      upcomingShipments: {
        heading: "Kommende forsendelser",
        orderNaming: "Ordre", // order id will be joined automatically
        orderDatePrefix: "I dag",
        orderTimePrefix: "ved", // for example: "Today *at* 9:00 am"
        dropdownNaming: "Alle lokasjoner",
      },
    },
  },
  bom: {
    newBom: {
      SelectComponent: "Velg komponent",
      AddNewComponent: "Legg til ny komponent",
      Product: "Produkt",
      Quantity: "Antall",
      Save: "Lagre",
      Cancel: "Avbryt",
      Components: "Komponenter",
      AddComponents: "Legg til komponenter",
      ComponentName: "Komponentnavn",
      UnitPrice: "Enhetspris",
      TotalPrice: "Totalpris",
      InStock: "På lager",
      Action: "Handling",
      BOMName: "BOM-navn",
      SelectAProduct: "Velg et produkt",
      CreateBillofMaterials: "Opprett materialliste",
      SelectProduct: "Velg produkt"
    },
    details: {
      "Productname": "Produktnavn",
      "Quantity": "Antall",
      "UnitPrice": "Enhetspris",
      "TotalPrice": "Totalpris",
      "InStock": "På lager"
    },
    bomMain: {
      "All": "Alle",
      "ComponentName": "Komponentnavn / Kode",
      "ProductName": "Produktnavn",
      "Quantity": "Antall",
      "UnitPrice": "Enhetspris",
      "InStock": "På lager",
      "ImportSuccess": "Importer produktet vellykket",
      "ImportFailed": "Feilet import av produkt:",
      "ExportFailed": "Klarte ikke å eksportere produkt:",
      "ExportSuccess": "Produktet ble eksportert vellykket",
      "ConfirmDeletion": "Bekreft sletting",
      "YouCannotChange": "Du kan ikke ombestemme deg når slettingen er fullført.",
      "Cancel": "Avbryt",
      "Delete": "Slett",
      "BOMS": "Stykkliste:",
      "ExportComponents": "Eksporter komponenter",
      "Export": "Eksporter",
      "Heading": "Overskrift",
      "Productname": "Produktnavn",
      "BOMname": "BOM-navn",
      "Category": "Kategori",
      "Components": "Komponenter"
    }
  },
  products: {
    allProducts: {
      table: {
        titleDeclinations: ["gjenstand", "gjenstander", "gjenstander"], // 1 -> item, 2 -> items, 5 -> items
        columns: [
          "Produktnavn",
          "Type",
            "Kategori",
          "Salgspris",
          "På lager",
          "Komponenter",
          "Handling",
        ],
      },
      modals: {
        deletion: {
          heading: "Bekreft sletting",
          subheading: "Du kan ikke ombestemme deg når slettingen er fullført.",
          buttons: {
            cancel: "Avbryt",
            delete: "Slett",
          },
          responses: {
            success: "Produktet ble slettet..",
            error: "En feil oppstod ved sletting av produkt.",
          },
        },
        exporting: {
          title: "Eksporter Produkter",
          dropdown: {
            label: "Overskrift",
            options: {
              productName: "Produktnavn",
              productCode: "Varenummer",
              barcode: "Strekkode",
              unit: "Enhet",
              group: "Varegruppe",
              defaultLocation: "Standard lokasjon",
              primarySupplier: "Standard leverandør",
              defaultTax: "Standard MVA",
              purchasePrice: "Innkjøpspris",
              sellingPrice: "Salgspris",
            },
          },
          okText: "Eksporter",
        },
      },

      filters: {
        import: {
          title: "Importer produkter",
        },
        dropdowns: {
          category: {
            label: "Kategori",
          },
          components: {
            label: "Komponenter",
            options: ["Nei", "Ja"],
          },
        },
        ranges: {
          sellingPrice: "Salgspris",
          quantityInStock: "Antall på lager",
        },
      },

      toasts: {
        deletion: {
          success: "Slett produktet vellykket",
          error: "Kunne ikke slette produktet", // + error
        },
        exporting: {
          success: "Eksporter produktet vellykket",
          error: "Kunne ikke eksportere produktet", // + error
        },
      },
    },
    newProduct: {
      heading: "Lag nye produkter",
      templates: {
        dropdown: {
          default: "Maler",
          nothingFound: "Ingen maler",
        },
      },
      modals: {
        saveTemplate: {
          heading: "Lagre mal",
          fields: {
            name: "Navn på mal",
          },
          buttons: {
            save: "Lagre",
          },
        },
      },
    },

    viewEditProduct: {
      heading: "Produkt detaljer",
      modals: {
        deletion: {
          heading: "Bekreft sletting",
          subheading: "Du kan ikke ombestemme deg når slettingen er fullført.",
          buttons: {
            cancel: "Avbryt",
            delete: "Slett",
          },
          responses: {
            success: "Produktet ble slettet",
            error: "Det oppstod en feil ved sletting av produkt",
          },
        },
      },
      tabs: {

        information: {
          heading: "Informasjon",
        },
        translations: {
          heading: "Oversettelser",
        },
        priceList: {
          heading: "Prisliste",
        },
        locations: {
          heading: "Lokasjoner",
          table: {
            columns: [
              "Lokasjon",
              "Antall på lager",
              "Min. antall på lager",
              "Min. salgs antall",
            ],
          },
          buttons: {
            save: "Lagre endringer",
          },
          responses: {
            save: {
              success: "Lokasjoner er oppdatert!",
              error: "En feil oppstod ved endring av lokasjoner...",
            },
          },
        },
        purchaseOrder: {
          heading: "Innkjøpsordre",
        },
        salesOrder: {
          heading: "Salgsordre",
        },
      },
      notFound: "Produkt ikke funnet",
    },

    createEditProduct: {

      variants: {
        imageUploading: {
          buttonText: "Legg til bilde",
        },
        fields: {
          name: {
            label: "Variant valg",
            placeholder: "Farge, størrelse",
          },
          value: {
            label: "Variant valg verdier",
          },
        },
        buttons: {
          addNewVariant: "Legg til variant",
        },
        notFound: "Ingen varianter lagt til",
      },

      additionalPrices: {
        fields: {
          sellingPrice: {
            label: "Salgspris",
          },
          priceList: {
            label: "Prisliste",
            placeholder: "Velg prisliste",
          },
        },
        buttons: {
          addNewPrice: "Legg til ny pris",
        },
        notFound: "Ingen ekstra priser ble lagt til",
      },

      packingDimensions: {
        heading: "Pakke størrelse",
        fields: {
          code: "Pakkenummer",
          quantity: "Produktantall",
          weight: "Vekt",
          width: "Bredde",
          height: "Høyde",
          length: "Lengde",
          cbm: "CBM",
        },
        buttons: {
          addPackingUnit: "Legg til pakkeenhet",
        },
      },

      attachment: {
        imagePrompt: "Dra bilde hit",
        defaultImage: "Standardbilde",
        setAsDefault: "Sett som standard",
      },

      modals: {
        unit: {
          createButton: "Opprett",
          creationModal: {
            heading: "Opprett enhet",
            nameField: {
              label: "Navn",
              placeholder: "Kg, stk, liter",
            },
            createButton: "Opprett og bekreft",
            responses: {
              success: "Enhet er opprettet",
              error: "En feil oppstod ved oppretting av enhet",
            },
          },
          deletionModal: {
            heading: "Bekreft sletting",
            subheading: "Er du sikker på at du vil slette denne enheten?",
            cancel: "Avbryt",
            delete: "Slett",
            responses: {
              success: "Enhet har blitt slettet",
              error: "En feil oppstod ved sletting av enhet",
            },
          },
        },

        category: {
          createButton: "Opprett",
          creationModal: {
            heading: "Opprett kategori",
            numberField: {
              label: "ID nummer",
            },
            nameField: {
              label: "Navn",
            },
            createButton: "Opprett og bekreft",
            responses: {
              success: "Kategorien ble opprettet",
              idNotUnique: "Kategori ID skal være unik",
              error: "En feil oppstod ved oppretting av enhet",
            },
          },
        },

        location: {
          createButton: "Opprett",
          creationModal: {
            heading: "Opprett lokasjon",
            locationField: {
              label: "Lokasjonsnavn",
            },
            sectionsCheckbox: {
              title: "Seksjoner",
              tip: "...",
            },
            sectionField: {
              label: "Seksjonsnavn",
            },
            createButton: "Opprett og bekreft",
            responses: {
              success: "Lokasjon er opprettet",
              error: "En feil oppstod ved opprettelse av lokasjon",
            },
          },
        },

        supplier: {
          createButton: "Opprett",
          creationModal: {
            heading: "Opprett leverandør",
            fields: {
              supplierName: "Leverandørnavn",
              supplierCode: "Leverandørnummer",
              street: "Gate",
              street2: "Gate 2",
              zipcode: "Postnummer",
              city: "By",
            },
            dropdowns: {
              country: {
                label: "Land",
                placeholder: "Velg land",
              },
              group: {
                label: "Gruppe",
                placeholder: "Velg gruppe",
              },
              paymentTerm: {
                label: "Betalingsbetingelser",
                placeholder: "Velg betalingsbetingelse",
              },
              defaultCurrency: {
                label: "Standard valuta",
                placeholder: "Velg valuta",
              },
              shipmentTerm: {
                label: "Fraktbetingelser",
                placeholder: "Velg fraktbetingelse",
              },
              shipmentMethod: {
                label: "Fraktmetode",
                placeholder: "Velg fraktmetode",
              },
            },

            categories: {
              address: "Addresse",
              options: "Valg",
            },

            createButton: "Opprett og lagre",

            responses: {
              success: "Leverandør har blitt opprettet",
              error: "Det oppstod en feil ved opprettelse av leverandør",
            },
          },
        },

        priceList: {
          createButton: "Opprett",
          creationModal: {
            heading: "Opprett prisliste",
            fields: {
              name: {
                label: "Prisliste navn",
              },
              code: {
                label: "Prislistenummer",
              },
            },
            dropdowns: {
              currency: {
                label: "Valuta",
                placeholder: "Velg valuta",
              },
            },
            createButton: "Opprett og lagre",
            responses: {
              success: "Prislisten er opprettet",
              error: "En feil oppstod ved opprettelse av prisliste",
            },
          },
        },

        currency: {
          createButton: "Opprett",
          creationModal: {
            heading: "Opprett ny valuta",
            dropdowns: {
              code: {
                label: "Valutanummer",
                placeholder: "Velg valutanummer",
              },
            },
            symbol: {
              label: "Symbol",
            },
            createButton: "Opprett og lagre",
            responses: {
              success: "Valuta er lagt til",
              error: "En feil oppstod ved opprettelse av valuta",
            },
          },
        },

        imagesUploading: {
          heading: "Last opp bilde",
          addImage: "Legg til bilde",
          close: "Lukk",
        },

        templates: {
          buttons: {
            create: "Opprett produkt og lagre mal",
            manage: "Behandle maler",
            delete: "Slett mal",
            save: "Lagre endringer",
          },

          creationModal: {
            heading: "Lagre mal",
            fields: {
              name: "Navn på mal",
            },
            buttons: {
              save: "Lagre",
            },
            responses: {
              success: "Mal er opprettet og lagt til",
              error: "En feil oppstod ved lagring av mal",
            },
          },

          deletionModal: {
            heading: "Bekreft sletting",
            subheading: "Er du sikker på at du vil slette?",
            templateInterpretation: "Mal",
            buttons: {
              cancel: "Avbryt",
              delete: "Slett",
            },
            responses: {
              success: "Malen er slettet!",
              error: "En feil oppstod ved sletting av mal!",
            },
          },

          savingProcess: {
            responses: {
              success: "Malen er oppdatert!",
              error: "En feil oppstod ved oppdatering av mal!",
            },
          },
        },
      },

      buttons: {
        create: "Opprett produkt",
        save: "Lagre",
        cancel: "Avbryt",
      },
    },

    general: {
      categories: {
        general: "Generell",
        options: "Alternativer",
        attachments: "Bildebeskrivelse",
        prices: "Priser",
        weights: "Vekt & Størrelse",
        inventory: "Inventar",
      },
      fields: {
        productName: "Produkt navn",
        productCode: "Produkt kode",
        barcode: "Barcode",
        unit: {
          label: "Enhet",
          placeholder: "Velg enhet",
        },
        category: {
          label: "Kategori",
          placeholder: "Velg kategori",
        },
        defaultLocation: {
          label: "Standard plassering",
          placeholder: "Velg lokasjon",
        },
        primarySupplier: {
          label: "Primærleverandør",
          placeholder: "Velg leverandør",
        },
        defaultTax: {
          label: "Standard MVA",
          placeholder: "Velg mvasats",
        },

        attachmentDescription: "Beskrivelse",

        rfid: {
          title: "RFID",
          tip: "...",
        },
        batchNumber: {
          title: "Batch nummer",
          tip: "Merk av for dette alternativet hvis produktet har serienummer. Transaksjoner som varemottak og leveranser vil bli utført med serienummer.",
        },
        serialNumber: {
          title: "Serie nummer",
          tip: "Merk av for dette alternativet hvis produktet har serienummer. Transaksjoner som varemottak og leveranser vil bli utført med serienummer.",
        },
        components: {
          title: "Komponenter",
          tip: "Har dette produktet komponenter?",
        },
        variants: {
          title: "Varianter",
          tip: "Har dette produktet varianter? Du vil ikke kunne legge til varianter senere hvis du ikke velger dette alternativet når du oppretter et nytt produkt.",
        },

        purchasePrice: "Innkjøpspris",
        sellingPrice: "Salgspris",

        options1: "Alternativer",
        priceList: {
          title: "Prisliste",
          tip: "Har dette produktet flere salgspriser?",
        },

        weight: "Vekt",
        cbm: "CBM",
        width: "Bredde",
        height: "Høyde",
        length: "Lengde/Dybde",
        options2: "Opsjoner",
        packagingUnit: {
          title: "Pakke enhet",
          tip: "Har dette produktet pakkeenhet? Appen vil automatisk tabulere parameterne i forsendelsespakkelisten din under forsendelse.",
        },

        inStock: "På lager",
        invQty: "Min. lagermengde",
        purchaseQty: "Min. Innkjøpsmengde",
        saleQty: "Min. salgsantall",
      },
    },
  },

  inventory: {
    stock: {
      table: {
        titleDeclinations: ["gjenstand", "gjenstander", "gjenstander"], // 1 -> item, 2 -> items, 5 -> items
        columns: [
          "Produkt navn",
          "Kategori",
          "Innkjøpspris",
          "På lager",
          "Handling",
        ],
      },
      subtable: {
        columns: ["Lokasjon", "Seksjon", "Antall"],
      },
      modals: {
        filter: {
          fields: {
            category: {
              label: "Kategori",
            },
            purchasePrice: {
              label: "Innkjøpspris",
            },
            inStock: {
              label: "På lager",
            },
          },
        },
        export: {
          title: "Eksporter lager",
          fields: {
            export: {
              label: "Eksport",
              dropdown: {
                chooseAll: "Alle",
                chooseFirst: "Først",
                chooseSelected: "Valgt",
              },
            },
            customNumberField: {
              placeholder: "Legg antall produkter du ønsker å eksportere",
            },
            exportTo: {
              label: "Eksporter til",
              options: ["Excel", "PDF"],
            },
          },
          exportButton: "Eksport",
        },
      },
    },
    adjustments: {
      table: {
        titleDeclinations: ["gjenstand", "gjenstander", "gjenstander"], // 1 -> item, 2 -> items, 5 -> items
        columns: [
          "Produktnavn/Justerings-ID",
          "Lokasjon",
          "Bruker",
          "Tilpasninger",
          "Merknader",
          "Dato",
          "Handling",
        ],
        adjustmentTypes: {
          quantity: "Antall",
          costPrice: "Innkjøpspris",
        },
      },

      createViewEditAdjustments: {
        fieldsGeneral: {
          product: {
            label: "Produkt",
            placeholder: "Velg produkt",
          },
          location: {
            label: "Lokasjon",
            placeholder: "Velg lokasjon",
          },
          date: {
            label: "Dato",
          },
          adjustment: {
            label: "Lagerjusteringer",
            selectQuantity: "Antall",
            selectCostPrice: "Innkjøpspris",
          },
          remarks: {
            label: "Merknader",
          },
        },

        // Those appear only if Quantity is selected
        fieldsCreateQuantityAdjustment: {
          alvailableQuantity: {
            label: "Tilgjengelig mengde",
          },
          actualQuantity: {
            label: "Faktisk mengde",
          },
          availableQuantityDifference: {
            label: "Tilgjengelig mengde (forskjell)",
          },
        },

        // Those appear only if Cost price is selected
        fieldsCreateCostPriceAdjustment: {
          costPrice: {
            label: "Innkjøpspris",
          },
          actualCostPrice: {
            label: "Faktisk kostpris",
          },
          actualCostPriceDifference: {
            label: "Faktisk kostpris (forskjell)",
          },
        },
      },

      createAdjustment: {

        heading: "Opprett ny lagerjustering",
        button: "Lagre",
        responses: {
          success: "Lagerjusteringen var velykket!",
          error: "Det oppstod en feil ved justering av lager!",
        },
      },

      editAdjustment: {

        heading: "Lagerjustering",
        button: "Lagre endringer",
        notFound: "Ingen lagerjustering funnet!",
        responses: {
          success: "Lagerjusteringen var velykket!",
          error: "En feil oppstod ved justering av lager!",
        },
      },

      viewAdjustment: {
        heading: "Lagerjustering",
      },

      notFound: "Ingen lagerjustering funnet",

      modals: {
        filter: {
          fields: {
            product: {
              label: "Produkt",
            },
            adjustment: {
              label: "Kjøpesum",
            },
            location: {
              label: "Kjøpesum",
            },
            user: {
              label: "Bruker",
            },
            remarks: {
              label: "Bemerkninger",
              dropdown: {
                yes: "Ja",
                no: "Nei",
              },
            },
            date: {
              label: "Dato",
              placeholder: "Velg dato",
            },
          },
        },
        confirmDelete: {
          label: "Bekreft sletting",
          hint: "Er du sikker på at du vil slette ",
          deletionItem: "Lagerjustering",
          buttons: {
            cancel: "Avbryt",
            delete: "Slett",
          },
          responses: {
            success: "Lagerjustering er slettet!", // TODO: +
            error: "En feil oppstod ved sletting av lagerjustering!", // TODO: +
          },
        },
      },
    },
    collections: {
      table: {
        titleDeclinations: ["gjenstand", "gjenstander", "gjenstander"], // 1 -> item, 2 -> items, 5 -> items
        columns: ["Samlings-ID", "Navn", "Produkter", "Handling"],
      },
      subtable: {
        columns: ["ID", "Produkt", "Kvantitet"],
      },
      createNewCollection: {
        fields: {
          idNumber: {
            tooltip: "ID-nummer kan stilles inn automatisk",
            label: "ID nummer",
            placeholder: "Skriv inn ID-nummer",
          },
          name: {
            label: "Navn",
            placeholder: "Skriv inn samlingens navn",
          },
          barcode: {
            label: "Strekkode",
            placeholder: "...",
          },
          products: {
            placeholder: "Ingen produkter",
            addButton: "Legg til produkt",
          },
        },
        modals: {
          addProduct: {
            title: "Legg til produkt",
            fields: {
              product: {
                label: "Produkt",
                placeholder: "Velg produkt",
              },
              quantity: {
                label: "Kvantitet",
                placeholder: "...",
              },
            },
            saveAndNewButton: "Lagre og ny",
            saveButton: "Lagre",
          },
        },
        saveButton: "Lagre",
      },
    },
    stockTransfers: {
      table: {
        titleDeclinations: ["gjenstand", "gjenstander", "gjenstander"], // 1 -> item, 2 -> items, 5 -> items
        columns: [
          "Produktnavn/overførings-ID",
          "Fra lokasjon",
          "Til lokasjon",
          "Bruker",
          "Merknader",
          "Dato",
          "Handling",
        ],
      },

      modals: {
        filter: {
          fields: {
            product: {
              label: "Produkt navn",
            },
            user: {
              label: "Bruker",
            },
            fromLocation: {
              label: "Fra lokasjon",
            },
            toLocation: {
              label: "Til lokasjon",
            },
            remarks: {
              label: "Merknader",
              options: {
                yes: "Yes", //TODO: +
                no: "No", //TODO: +
              },
            },
            date: {
              label: "Merknader",
              placeholder: "Velg periode",
            },
          },
          productLabel: "Produkt",
          productDropdownOptions: {
            chooseAllLabel: "Alle",
            // add more options
          },
          adjustmentLabel: "Tilpasninger",
          locationLabel: "Lokasjon",
          locationDropdownOptions: {
            chooseAllLabel: "Alle",
            // add more options
          },

          userLabel: "Bruker",
          userDropdownOptions: {
            chooseAllLabel: "Alle",
            // add more options
          },
          dateLabel: "Dato",
          remarksLabel: "Merknader",
          remarksDropdownOptions: {
            chooseAllLabel: "Alle",
            // add more options
          },
        },

        confirmDelete: {

          heading: "Slett lageroverføring",
          subheading: "Er du sikker på at du vil slette?",
          deletionItem: "Lageroverføring",
          buttons: {
            cancel: "Avbryt",
            delete: "Slett",
          },
          responses: {
            success: "Lageroverføringen ble slettet!",
            error: "En feil oppstod ved sletting av lageroverføring...",
          },
        },
      },

      stockTransfer: {
        disables: {

          fromLocation: {
            noProduct: "Velg produkt først",
          },
          section: {
            noLocation: "Velg lokasjon først",
            noSection: "Lokasjonen har ingen seksjoner",
          },
          toLocation: {
            noFromLocation: "Velg fra lokasjon først",
            noFromSection: "Velg fra seksjon først",
          },
        },
        fields: {
          product: {
            label: "Produkt",
            placeholder: "Velg produkt",
          },
          fromLocation: {
            label: "Fra lokasjon",
            placeholder: "Velg lokasjon",
          },
          section: {
            label: "Seksjon",
            placeholder: "Velg seksjon",
          },
          toLocation: {
            label: "Til lokasjon",
            placeholder: "Velg lokasjon",
          },
          date: {
            label: "Etikett",
          },
          availableQuantity: {
            label: "Tilgjengelig mengde",
          },
          transferQuantity: {
            label: "Overføringsmengde",
          },
          remarks: {
            label: "Merknader",
          },
        },
        saveButton: "Lagre",
        notFound: "Lageroverføring ble ikke funnet",
      },
      createTransfer: {

        heading: "Opprett ny lageroverføring",
        responses: {
          success: "Lageroverføringen ble opprettet!",
          error: "En feil oppstod ved opprettelse av lageroverføring...",
        },
      },
      viewEditTransfer: {
        heading: "Lageroverføring",
        responses: {
          success: "Lageroverføringen har blitt oppdatert!",
          error: "En feil oppstod ved oppdatering av lageroverføringen...",
        },
      },
      tooltip: "...",
    },
  },
  customers: {

    main: {
      table: {
        columns: [
          "Kundenavn / kode",
          "E-post",
          "Telefon",
          "Kontaktperson navn",
          "Handling",
        ],
        titleDeclinations: ["kunde", "kunder", "kunder"],
      },
      modals: {
        export: {
          heading: "Eksporter kunder",
          dropdown: {
            label: "Tittel",
            placeholder: "Velg tittel",
            options: {
              companyName: "Firmanavn",
              email: "E-post",
              phone: "Telefon",
              country: "Land",
              vat: "Organisasjonsnummer",
              contactPerson: "Kontaktperson",
              address: "Addresse",
              group: "Gruppe",
            },
          },
          buttons: {
            export: "Eksport",
          },
        },
      },
    },
    creation: {
      heading: "Opprett ny kunde",
      responses: {
        success: "Kunde er opprettet!",
        error: "En feil oppstod ved opprettelse av kunde...",
      },
    },
    editing: {
      responses: {
        success: "Kunde er oppdatert!",
        error: "En feil oppstod ved oppdatering av kunde...",
      },
    },
    general: {
      modals: {
        deletion: {
          heading: "Bekreft sletting",
          subheading: "Er du sikker på at du vil slette",
          buttons: {
            cancel: "Avbryt",
            delete: "Slett",
          },
          responses: {
            success: "Kunde er slettet!",
            error: "En feil oppstod ved sletting av kunde...",
          },
        },
      },
      tabs: {
        general: {
          heading: "Generelt",
          categories: {
            contactInfo: "Kontaktinformasjon",
            additionalContactInfo: "Ekstra kontaktinformasjon",
          },
          fields: {
            customerName: {
              label: "Kundenavn",
            },
            customerCode: {
              label: "Kundenummer",
            },
            VAT: {
              label: "Organisasjonsnummer",
            },
            taxRate: {
              label: "MVA gruppe",
            },
            discount: {
              label: "Rabatt",
              placeholder: "Legg til rabatt",
            },
            contactName: {
              label: "Kontakt navn",
            },
            phone: {
              label: "Telefon",
            },
            email: {
              label: "E-post",
            },
          },
          buttons: {
            addContact: "Legg til ny kontakt",
            delete: "Slett",
            next: "Neste",
          },
        },

        pricesAndDiscounts: {
          heading: "Priser og rabatter",
        },

        address: {
          heading: "Addresse",
          categories: {
            billing: "Fakturaadresse",
            delivery: "Leveringsadresse",
          },
          fields: {
            name: {
              label: "Navn",
            },
            street: {
              label: "Gate",
            },
            street2: {
              label: "Gate 2",
            },
            zipcode: {
              label: "Postnummer",
            },
            city: {
              label: "By",
            },
            country: {
              label: "Land",
            },
            contact_person: {
              label: "Kontaktperson",
            },
            phone: {
              label: "Telefon",
            },
            email: {
              label: "E-post",
            },
          },
          checkboxes: {
            useForShipping: "Bruk denne adressen som leveringsadresse",
            useAsPrimary: "Bruk denne adressen som standard leveringsadresse",
          },
          buttons: {
            addDelivery: "Legg til ny leveringsadresse",
            delete: "Slett",
            prev: "Tilbake",
            next: "Lagre",
          },
        },
        settings: {
          heading: "Innstillinger",
        },
        salesOrders: {
          heading: "Salgsordre",
        },
      },
    },
  },
  suppliers: {

    main: {
      table: {
        columns: [
          "Leverandørnavn",
          "E-post",
          "Telefon",
          "Kontakt navn",
          "Handling",
        ],
        titleDeclinations: ["leverandør", "leverandører", "leverandører"],
      },
      modals: {
        export: {
          heading: "Eksporter leverandører",
          dropdown: {
            label: "Tittel",
            placeholder: "Velg tittel",
            options: {
              companyName: "Leverandørnavn",
              email: "E-post",
              phone: "Telefon",
              country: "Land",
              vat: "Organisasjonsnummer",
              contactPerson: "Kontaktperson",
              address: "Addresse",
            },
          },
          buttons: {
            export: "Eksporter",
          },
        },
      },
    },
    creation: {
      heading: "Opprett ny leverandør",
      responses: {
        success: "Leverandør ble opprettet!",
        error: "En feil oppstod ved opprettelse av leverandør...",
      },
    },
    editing: {
      responses: {
        success: "Leverandør er oppdatert!",
        error: "En feil oppstod ved oppdatering av leverandør...",
      },
    },

    general: {
      modals: {
        deletion: {
          heading: "Bekreft sletting",
          subheading: "Er du sikker på at du vil slette",
          buttons: {
            cancel: "Avbryt",
            delete: "Slett",
          },
          responses: {
            success: "Leverandør har blitt slettet!",
            error: "Det oppstod en feil ved sletting av leverandør...",
          },
        },
      },

      tabs: {
        general: {
          heading: "Generelt",
          categories: {
            contactInfo: "Kontaktinformasjon",
            additionalContactInfo: "Ekstra kontaktinformasjon",
          },
          fields: {
            supplierName: {
              label: "Leverandørnavn",
            },
            supplierCode: {
              label: "Leverandørnummer",
            },
            VAT: {
              label: "Organisasjonsnummer",
            },
            currency: {
              label: "Valuta",
            },
            taxRate: {
              label: "MVA sats",
            },
            paymentTerms: {
              label: "Betalingsbetingelser",
            },
            contactName: {
              label: "Kontakt navn",
            },
            phone: {
              label: "Telefon",
            },
            email: {
              label: "E-post",
            },
          },
          buttons: {
            addContact: "Legg til ny kontakt",
            delete: "Slett",
            next: "Neste",
          },
        },

        address: {
          heading: "Addresse",
          categories: {
            billing: "Fakturaadresse",
            delivery: "Leveringsadresse",
          },
          fields: {
            name: {
              label: "Navn",
            },
            street: {
              label: "Gate",
            },
            street2: {
              label: "Gate 2",
            },
            zipcode: {
              label: "Postnummer",
            },
            city: {
              label: "By",
            },
            country: {
              label: "Land",
            },
            contact_person: {
              label: "Kontaktperson",
            },
            phone: {
              label: "Telefon",
            },
            email: {
              label: "E-post",
            },
          },
          checkboxes: {
            useForReturn: "Bruk denne adressen som leveringsadresse",
            useAsPrimary: "Bruk denne adressen som standard leveringsadresse",
          },
          buttons: {
            addDelivery: "Legg til ny leveringsadresse",
            delete: "Slett",
            prev: "Tilbake",
            next: "Lagre",
          },
        },
        purchaseOrders: {
          heading: "Innkjøpsordre",
        },
        receives: {
          heading: "Motta",
        },
      },
    },
  },
  settings: {
    sidebarLinks: { // TODO: [+nekear] added, but not implemented
      general: {
        title: "Generelt"
      },
      companyInformation: {
        title: "Firmaopplysninger"
      },
      defaults: {
        title: "Standardverdier"
      },
      locations: {
        title: "Steder"
      },
      currencies: {
        title: "Valutaer"
      },
      paymentTerms: {
        title: "Betalingsbetingelser"
      },
      deliveryTerms: {
        title: "Leveringsbetingelser"
      },
      languages: {
        title: "Språk"
      },
      taxRates: {
        title: "Skattesatser"
      },
      myAccount: {
        title: "Min konto"
      }
    },
    general: {
      paymentTerms: {
        heading: "Betalingsbetingelser",
        table: {
          columns: ["Navn", "Dager", "Type"],
          buttons: {
            add: "Legg til betalingsbetingelser"
          }
        },
        modals: {
          mutation: {
            creationHeading: "Legg til nye betalingsbetingelser",
            fields: {
              name: {
                label: "Navn"
              },
              days: {
                label: "Dager"
              }
            },
            dropdowns: {
              type: {
                label: "Type",
                placeholder: "Velg"
              }
            },
            responses: {
              creation: {
                success: "Betalingsbetingelser er opprettet!",
                error: "Noe gikk galt!"
              },
              update: {
                success: "Betalingsbetingelser oppdatert vellykket!",
                error: "Noe gikk galt!"
              }
            },
            buttons: {
              save: "Lagre"
            }
          },
          deletion: {
            heading: "Bekreft sletting",
            subheading: "Du kan ikke endre mening når slettingen er fullført",
            buttons: {
              cancel: "Avbryt",
              delete: "Slett"
            },
            responses: {
              success: "Betalingsbetingelser er slettet!",
              error: "Det oppstod en feil under slettingen av betalingsbetingelsene!"
            }
          }
        },
        paymentTypes: {
          daysAfter: "Dager etter",
          prepayment: "Forhåndsbetaling"
        }
      },
      deliveryTerms: {
        heading: "Leveringsbetingelser",
        table: {
          columns: ["Name", "Beskrivelse"],
          buttons: {
            add: "Legg til leveringsbetingelser",
          }
        },
        modals: {
          mutation: {
            creationHeading: "Legg til nye leveringsbetingelser",

            fields: {
              name: {
                label: "Navn"
              },
              description: {
                label: "Beskrivelse"
              }
            },

            responses: {
              creation: {
                success: "Leveringsbetingelser er opprettet!",
                error: "Noe gikk galt!"
              },
              update: {
                success: "Leveringsbetingelser oppdatert vellykket!",
                error: "Noe gikk galt!"
              }
            },

            buttons: {
              save: "Lagre"
            }
          },
          deletion: {
            heading: "Bekreft sletting",
            subheading: "Du kan ikke endre mening når slettingen er fullført",
            buttons: {
              cancel: "Avbryt",
              delete: "Slett"
            },
            responses: {
              success: "Leveringsbetingelser er slettet!",
              error: "Det oppstod en feil under slettingen av betalingsbetingelsene!"
            }
          }
        }
      },
      companyInformation: {
        tabs: {
          companyDetails: {
            title: "Firma detaljer",
            logo: {
              label: "Logo",
              restriction:
                ["Denne logoen vil vises på transaksjoner og e-postvarsler.", "Foretrukket bildestørrelse: 240px x 240px @ 72 DPI Maksimal størrelse på 1MB."],
              prompt: "Dra bildet hit", // manual by nekear
            },
            fields: {
              companyName: "Selskapsnavn",
              street: "Gatenavn",
              city: "By",
              zipCode: "post kode",
              phone: "Telefonnummer",
              email: "Epost",
              website: "Nettside",
            },
            dropdowns: {
              industry: {
                label: "Industri",
                placeholder: "Velg",
              },
              country: {
                label: "Land",
                placeholder: "Velg",
              },
              baseCurrency: {
                label: "Grunnvaluta",
                placeholder: "Velg",
              },
              language: {
                label: "Språk",
                placeholder: "Velg",
              },
              timezone: {
                label: "Tidssone",
                placeholder: "Velg",
              },
            },
            actions: {
              createNewCompanyButton: "Legg til nytt selskap",
              createNewCompanyButtonDropdown: "Opprett ny", // manual by nekear
              selectCompaniesDropdown: "Velg selskap", // manual by nekear
            },

            buttons: {
              next: "Neste" // manual by nekear
            },
          },
          address: {
            title: "Addresse",
            useThisAddressCheckbox: "Bruk denne adressen for levering",
            billingAddress: {
              heading: "Faktureringsadresse", // manual by nekear
              fields: {
                name: "Navn",
                country: "Land",
                street: "Gatenavn",
                street2: "Adresselinje 2",
                zipCode: "Post kode",
                city: "By",
                email: "Epost",
                phone: "Telefonnummer",
                contactName: "Kontakt navn",
              },
            },
            deleteAddressButton: "Slett",
            modals: {
              deletion: {
                title: "Bekreft sletting",
                hintText: "Er du sikker på at du vil slette adressen?",
                cancelButton: "Avbryt",
                deleteButton: "Slett",
              },
            },
            deliveryAddress: {
              heading: "Leveringsadresse", // manual by nekear
              fields: {
                name: "Navn",
                country: "Land",
                street: "Gatenavn",
                street2: "Adresselinje 2",
                zipCode: "Post kode",
                city: "By",
                email: "E post",
                phone: "Telefonnummer",
                contactName: "Kontakt navn",
              },
            },
            addNewDeliveryAddressButton: "Legg til ny leveringsadresse",
            buttons: {
              previous: "Tilbake",
              next: "Lagre",
            }
          },
          billing: {
            contactEmailDetails: {
              title: "Kontakt E post",
              accountEmail: "Send til min konto e-post",
              alternativeEmail: {
                title: "Send til en alternativ e-post",
                placeholder: "Skriv inn alternativ e-post",
              },
            },
            modals: {
              addNewPaymentDetails: {
                title: "Legg til ny betaling",
                fields: {
                  cardNumber: "Kortnummer",
                  expirationDate: "Utløpsdato",
                  CVCCode: "CVC/CVV",
                  cardholderName: "Kortholders navn",
                },
                addCard: "Legg til kort",
              },
            },
            cardDetails: {
              title: "Kortdetaljer",
              addNewPayment: "Legg til ny betaling",
            },
            paymentHistory: {
              title: "Betalingshistorikk",
              columns: ["Faktura", "PO", "Beløp", "Dato", "Status", "Handling"],
              status: {
                paid: "Betalt",
              },
            },
          },
          notifications: {
            title: "Varsler",
            columns: ["Seksjon", "Handling", "Muliggjør"],
            sections: {
              purchaseOrder: {
                title: "Bestilling",
                actions: {
                  placed: "Plassert",
                  completed: "Fullført",
                  cancelled: "Avbrutt",
                },
              },
              salesOrder: {
                title: "Salgsordre",
                actions: {
                  placed: "Plassert",
                  completed: "Fullført",
                  cancelled: "Avbrutt",
                },
              },
              shipment: {
                title: "Forsendelse",
                actions: {
                  shipped: "Sendes",
                  delivered: "Levert",
                },
              },
              receive: {
                title: "Motta",
                actions: {
                  inProgress: "I prosess",
                  received: "Mottatt",
                },
              },
            },
          },
        },
      },
      locations: {
        table: {
          columns: ["Lokasjonsnavn", "Land", "By", "Seksjoner", "Handling"],
        },
        subtable: {
          // TODO +
          rowHints: ["Section name", "Sector", "Row", "Shelf height"],
        },

        createEditLocation: {
          location: {
            fields: {
              locationName: {
                label: "Lokasjons navn",
              },
              country: {
                label: "Land",
                placeholder: "Velg land",
              },
              city: {
                label: "By",
              },
              street: {
                label: "Gatenavn",
              },
              zipCode: {
                label: "Post kode",
              },
              phoneNumber: {
                label: "Telefonnummer",
              },
              contactName: {
                label: "Kontaktnavn",
              },
              email: {
                label: "E post",
              },
              options: {
                label: "Valg",
                sectionsOption: "Seksjoner",
              },
            },
          },

          sections: {
            title: "Seksjoner",
            fields: {
              name: {
                label: "Seksjons navn",
              },
              sector: {
                label: "Sektor",
              },
              shelfHeight: {
                label: "Hyllehøyde",
              },
            },
          },
          nothingFound: "Finner ingen seksjoner",
          button: "Legg til ny seksjon",
        },

        createLocation: {

          heading: "Opprett ny lokasjon",
          button: "Opprett lokasjon",
          responses: {
            success: "Lokasjonen ble opprettet!",
            error: "En feil oppstod ved opprettelse av lokasjon!",
          },
        },

        editLocation: {

          button: "Lagre endringer",
          responses: {
            success: "Lokasjon ble oppdatert!",
            error: "En feil oppstod ved oppdatering av lokasjon!",
          },
        },

        modals: {
          filter: {
            fields: {
              country: {
                label: "Land",
                placeholder: "Velg",
              },
              sections: {
                label: "Seksjoner",
              },
            },
          },

          // both for sections and locations
          confirmDelete: {

            label: "Bekreft sletting",
            hint: "Er du sikker på at du vil slette",
            sectionDeletionItem: "seksjon",
            cancelButton: "Avbryt",
            deleteButton: "Slett",
            responses: {
              location: {
                success: "Seksjon ble slettet!",
                error: "En feil oppstod ved sletting av seksjon!",
              },
            },
          },
        },
      },
      languages: {
        title: "Språk",
        columns: ["Navn", "Språk kode"],
        addLanguage: "Legg til språk",
        modals: {
          newLanguage: {
            title: "Legg til språk",
            language: {
              label: "Språk",
              placeholder: "Velg",
            },
            languageCode: "Språk kode",
            saveButton: "Lagre",
          },
          deletion: {
            title: "Bekreft sletting",
            hintText: "Du kan ikke ombestemme deg når slettingen er fullført.",
            cancelButton: "Avbryt",
            deleteButton: "Slett",
          },
        },
      },
      myAccount: {
        tabs: {
          profile: {
            title: "Profil",
            fields: {
              firstName: "Fornavn",
              lastName: "Etternavn",
              email: "E post",
              phone: "Telefonnummer",
            },
            dropdowns: {
              country: {
                label: "Land",
                placeholder: "Velg",
              },
              language: {
                label: "Språk",
                placeholder: "Velg",
              },
            },
          },
          password: {
            title: "Passord",
            fields: {
              currentPassword: "Nåværende passord",
              newPassword: "Nytt passord",
              confirmPassword: "Bekreft passord",
            },
          },
          saveButton: "Lagre",
          users: {
            title: "Brukere",
            columns: ["Fult navn", "E post", "Rolle", "Siste innlogging"],
            inviteUser: "Inviter bruker",
            modals: {
              inviteUser: {
                title: "Inviter bruker",
                fields: {
                  name: "Navn",
                  email: "Epost addresse",
                  role: "Rolle",
                },
                inviteButton: "Inviter",
              },
            },
          },
          billing: {
            contactEmailDetails: {
              title: "Kontact E post",
              accountEmail: "Send til min konto e post",
              alternativeEmail: {
                title: "Send til en alternativ e-post",
                placeholder: "Skriv inn alternativ e-post",
              },
            },
            cardDetails: {
              title: "Kortdetaljer",
              addNewPayment: "Legg til ny betaling",
            },
            paymentHistory: {
              title: "Betalingshistorikk",
              columns: ["Faktura", "PO", "Beløp", "Dato", "Status", "Handling"],
              status: {
                paid: "Betalt",
              },
            },
          },
          subscription: {
            startPackage: {
              title: "Startpakke",
              setupAccount: "Sett opp Suppli-konto",
              setupIntegrations: "Sett opp integrasjoner",
              software: "Suppli programvare",
              coaching: "3 timers nettbasert opplæring i bruk av Suppli",
              price: "Kr 15 000",
              buyButton: "Kjøp nå",
            },
            license: {
              title: "Lisens",
              premium: {
                title: "Premium",
                price: "Kr 990",
                timing: "Måned pr bruker",
              },
              warehouseAndScanning: {
                title: "Lager – og scanning av produkter",
                price: "Kr 490",
                timing: "Måned pr bruker",
              },
              accounting: {
                title: "Regnskap",
                price: "Kr 290",
                timing: "Måned pr bruker",
              },
              businessPackage1: {
                title: "Bedriftspakke 1",
                price: "Kr 4990",
                timing: "Måned",
              },
              businessPackage2: {
                title: "Bedriftspakke 2",
                price: "Kr 9990",
                timing: "Måned",
              },
            },
            support: {
              title: "Brukerstøtte",
              bronze: {
                title: "Bronse",
                price: "Kr 999",
                timing: "Måned",
              },
              silver: {
                title: "Sølv",
                price: "Kr 1499",
                timing: "Måned",
              },
              gold: {
                title: "Gull",
                price: "Kr 1499",
                timing: "Måned",
              },
            },
            latestPayment: {
              title: "Siste betaling",
              nextPayment: "Neste betaling",
              payNow: "Betal nå",
              paymentDate: "Betalingsdato",
              typeOfPlan: "Type plan",
              paymentMethod: "Betalingsmetode",
              totalPayment: "Full betaling",
            },
          },
        },
      },
    },
  },

  purchaseOrder: {
    main: {
      table: {
        columns: ["Ordrenummer", "Leverandør", "Dato", "Motta", "Totalt", "Dokumenter", "Handling"],
      },
      subtable: {
        columns: ["Varenummer", "Produkt", "Antall", "Enhetspris", "Rabatt", "MVA", "Totalt"]
      },
      actions: {
        receive: "Motta",
        details: "Se detaljer",
        duplicate: "Dupliser",
        edit: "Endre",
        delete: "Slett",
      },
      filters: {
        supplier: {
          label: "Leverandør",
        },
        receive: {
          label: "Motta",
          options: {
            received: "Mottatt",
            inProgress: "Under behandling",
            notReceived: "Ikke mottatt",
          }
        },
        deliveryDate: {
          label: "Leveringsdato",
          placeholder: "Velg periode"
        }
      }
    },
    general: {
      categories: {
        references: "Referanse",
        address: "Addresse",
      },
      fields: {
        preferredDeliveryDate: {
          label: "Ønsket leveringsdato",
          placeholder: "Velg dato"
        },
        purchaseDate: {
          label: "Innkjøpsdato",
          placeholder: "Velg dato"
        },
        billingAddress: {
          label: "Fakturaadresse",
        },
      },
      accentFields: {
        status: {
          label: "Status",
          variants: {
            notReceived: "Ikke mottatt",
            inProgress: "Under behandling",
            received: "Mottatt",
          }
        },

        orderDate: {
          label: "Ordredato",
        },

        receivedAt: {
          label: "Mottatt den",
          variants: {
            notReceived: "Ikke mottatt",
            partiallyReceived: "Delvis mottatt",
            received: "Mottatt",
          }
        }
      },
      dropdowns: {
        supplier: {
          label: "Leverandør",
          placeholder: "Velg leverandør"
        },
        ourReference: {
          label: "Vår referanse",
          placeholder: "Velg referanse"
        },
        theirReference: {
          label: "Deres referanse",
          placeholder: "Velg referanse"
        },
        paymentTerms: {
          label: "Betalingsbetingelser",
          placeholder: "Velg betalingsbetingelse"
        },
        deliveryTerms: {
          label: "Leveringsbetingelser",
          placeholder: "Velg leveringsbetingelse"
        },
        currency: {
          label: "Valuta",
          placeholder: "Velg valuta"
        },
        deliveryMethod: {
          label: "Leveringsmetode",
          placeholder: "Velg leveringsmetode"
        },
        deliveryAddress: {
          label: "Leveringsadresse",
          placeholder: "Velg leveringsadresse"
        },
      },
      checkboxes: {
        useForDelivery: "Bruk denne adressen for levering"
      },
      orders: {
        heading: "Produkter",
        columns: ["Varenummer", "Produkt", "Antall", "Enhetspris", "Rabatt", "MVA", "Totalt", "Handling"],
        buttons: {
          addProduct: "Legg til produkt",
          addCollection: "Legg til samling",
        },
        summary: {
          subtotal: "Subtotal",
          taxes: "MVA",
          total: "Totalt",
        },
        noProducts: "Ingen produkter",
      },
      buttons: {
        create: "Opprett",
        save: "Lagre",
        sendToSupplier: "Send til leverandør",
      },
      toasts: {
        noProductsAdded: "Du må minst velge ett produkt!",
      },
      modals: {
        receive: {
          heading: "Motta innkjøpsordre",
          subheading: [
            "Vennligst velg",
            "Delvis",
            "(tillater å velge hvilke produkter kan mottas)",
            "eller",
            "Hurtigmottak",
            "(motta alle produkter automatisk)"
          ],
          buttons: {
            partial: "Delvis motta",
            quick: "Hurtigmottak",
          },
          responses: {
            error: "En feil oppstod ved hurtigmottak!",
            success: "Innkjøpsordre er mottatt!"
          }
        },
        deletion: {
          heading: "Bekreft sletting",
          subheading: "Er du sikker på at du vil slette",
          purchaseOrderDeletionItem: "Innkjøpsordre",
          buttons: {
            cancel: "Avbryt",
            delete: "Slett"
          },
          responses: {
            error: "En feil oppstod ved sletting av innkjøpsordre!",
            success: "Innkjøpsordre er slettet!"
          }
        }
      }
    },
    mutations: {
      creation: {
        heading: "Ny innkjøpsordre",
        responses: {
          success: "Innkjøpsordre er opprettet!",
          error: "En feil oppstod oppretting av innkjøpsordre!"
        }
      },
      editing: {
        heading: "Innkjøp",
        responses: {
          success: "Innkjøpsordre ble oppdatert!",
          error: "Det oppstod en feil ved oppdatering av innkjøpsordre!"
        }
      },

      details: {
        heading: "Innkjøp",
      }
    }
  },

  sales: {
    orders: {
      main: {
        table: {
          columns: ["Ordrenummer", "Kunde", "Sendt", "Fakturert", "Dato", "Totalt", "Dokumenter", "Handling"],
        },
        subtable: {
          columns: ["Varenummer", "Produkt", "Antall", "Enhetspris", "Rabatt", "MVA", "Totalt"]
        },
        actions: {
          addToPurchaseOrder: "Legg til i innkjøpsordre",
          details: "Se detaljer",
          duplicate: "Duplikat",
          edit: "Endre",
          delete: "Slett",
        },
        filters: {
          customer: {
            label: "Kunder",
          },
          date: {
            label: "Dato",
            placeholder: "Velg dato"
          },
          shipped: {
            label: "Sendt",
            options: {
              shipped: "Sendt",
              ready: "Klar",
              notShipped: "Ikke sendt",
            }
          },
          invoiced: {
            label: "Fakturert",
            options: {
              invoiced: "Fakturert",
              notInvoiced: "Ikke fakturert",
            }
          },
        }
      },
      general: {
        categories: {
          references: "Referanser",
          address: "Addresse",
        },
        fields: {
          preferredDeliveryDate: {
            label: "Ønsket leveringsdato",
            placeholder: "Velg dato"
          },
          orderDate: {
            label: "Ordredato",
            placeholder: "Velg dato"
          },
          billingAddress: {
            label: "Fakturaadresse",
            blockedBeforeCustomers: "Velg kunde først",
          },
        },
        accentFields: {
          status: {
            label: "Status",
            variants: {
              notReceived: "Ikke mottatt",
              inProgress: "Under behandling",
              received: "Mottatt",
            }
          },
          orderDate: {
            label: "Ordredato",
          },
          deliveryDate: {
            label: "Leveringsdato",
          }
        },

        dropdowns: {
          customer: {
            label: "Kunde",
            placeholder: "Velg kunde"
          },
          ourReference: {
            label: "Vår referanse",
            placeholder: "Velg referanse"
          },
          theirReference: {
            label: "Deres referanse",
            placeholder: "Velg referanse"
          },
          paymentTerms: {
            label: "Betalingsbetingelser",
            placeholder: "Velg betalingsbetingelse"
          },
          deliveryTerms: {
            label: "Leveringsbetingelser",
            placeholder: "Velg leveringsbetingelse"
          },
          currency: {
            label: "Valuta",
            placeholder: "Velg valuta"
          },
          deliveryMethod: {
            label: "Leveringsmetode",
            placeholder: "Velg leveringsmetode"
          },
          deliveryAddress: {
            label: "Leveringsadresse",
            placeholder: "Velg adresse"
          },
        },
        checkboxes: {
          useForDelivery: "Bruk denne adressen for levering"
        },
        orders: {
          heading: "Produkter",
          columns: ["Varenummer", "Produkt", "Antall", "Enhetspris", "Rabatt", "MVA", "Totalt", "Handling"],
          buttons: {
            addProduct: "Legg til produkt",
            addCollection: "Legg til samling",
          },
          summary: {
            subtotal: "Subtotal",
            taxes: "MVA",
            total: "Totalt",
          },
          noProducts: "Ingen produkter",
        },
        buttons: {
          create: "Opprett",
          save: "Lagre",
          sendToSupplier: "Send til kunde",
        },
        toasts: {
          noProductsAdded: "Du må velge minst ett produkt!",
        },
        modals: {
          deletion: {
            heading: "Bekreft sletting",
            subheading: "Er du sikker på at du vil slette",
            orderDeletionItem: "Ordre",
            buttons: {
              cancel: "Avbryt",
              delete: "Slett"
            },
            responses: {
              error: "En feil oppstod ved sletting av salgsordre!",
              success: "Salgsordre er slettet!"
            }
          }
        }
      },
      mutations: {
        creation: {
          heading: "Ny salgsordre",
          responses: {
            success: "Salgsordre er opprettet!",
            error: "Kunne ikke opprette salgsordre!"
          }
        },
        editing: {
          heading: "Salgsordre",
          responses: {
            success: "Salgsordre ble oppdatert!",
            error: "Det oppstod en feil ved oppdatering av salgsordre!"
          }
        },
        details: {
          heading: "Salgsordre",
        }
      }
    },
  },

  general: {
    // TODO: +++
    search: {
      placeholder: "Søk",
    },
    filter: {
      title: "Filter",
    },
    export: {
      title: "Eksport",
    },
    import: {
      title: "Import",
      action: "Velg fil",
    },
    creation: {
      title: "Legg til ny",
    },
    dropdownAll: "Alle",
    nothingFound: {
      table: "INGEN RESULTAT",
      record: "INGEN TREFF",
    },
    inDevelopment: "I utvikling",
    components: {
      tax: {
        dropdown: {
          label: "MVA sats",
          placeholder: "Velg MVA sats",
          buttons: {
            create: "Opprett",
          },
        },
        modals: {
          creation: {
            heading: "Opprett ny MVA sats",
            taxName: {
              label: "Navn",
            },
            taxRate: {
              label: "MVA sats",
            },
            buttons: {
              create: "Opprett og lagre",
            },
            responses: {
              success: "MVA sats ble opprettet!",
              error: "En feil oppstod ved opprettelse av MVA sats...",
            },
          },
        },
      },
      country: {
        dropdown: {
          label: "Land",
          placeholder: "Velg land",
        },
      },
      currency: {
        dropdown: {
          label: "Valuta",
          placeholder: "Velg valuta",
          buttons: {
            create: "Opprett",
          },
        },
        modals: {
          creation: {
            heading: "Opprett ny valuta",
            currencyCode: {
              label: "Valutakode",
              placeholder: "Velg valutakode",
            },
            symbol: {
              label: "Symbol",
            },
            responses: {
              success: "Valuta ble opprettet",
              error: "En feil oppstod ved opprettelse av valuta",
            },
            buttons: {
              create: "Opprett og lagre",
            },
          },
        },
      },
      paymentTerms: {
        dropdown: {
          label: "Betalingsbetingelser",
          placeholder: "Velg betalingsbetingelse",
        },
      },
    },
  },
};

export default translationEN;
