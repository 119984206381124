import React, { useMemo } from "react";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseTable from "@reusables/BaseTable";
import { useTranslation } from "react-i18next";
import { calculateOrderPrice, formatPriceWithCurrency, normalizePrice } from "@helpers/utils";
import _ from "lodash";
import { ViewLayoutProps } from "./index";
import { Can, useAbility } from "@/casl.config";
import { SaleOrder } from "@/types/general";
import BaseBadge from "@reusables/BaseBadge";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";
import BaseTooltip from "@/components/reusables/BaseTooltip";
import { MessageSquareText } from "lucide-react";
import { useAppSelector } from "@redux/hooks";

export interface TransformedLine {
    type: 'line' | 'group';
    id?: number;
    name?: string;
    module: 'bom' | 'collection';
    key?: string;
    data: SaleOrder.Components.LineData | undefined; // Always a single LineData or null for group headers
}

export default function OrderDetails({ saleOrder }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general" });

    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency);

    const ability = useAbility();

    const [collapsedGroups, setCollapsedGroups] = React.useState<Record<string, boolean>>({});

    const toggleCollapse = (groupKey: string) => {
        setCollapsedGroups(prevState => ({
            ...prevState,
            [groupKey]: !prevState[groupKey]
        }));
    };

    // Transform lines for display
    const transformedLines = useMemo(() => {
        return saleOrder.lines.map((line, index) => {
            if (line.type === "line") {
                return {
                    ...line,
                };
            } else if (line.type === "group") {
                return [
                    {
                        type: "group",
                        id: line.id,
                        name: line.name,
                        module: line.module,
                        key: index.toString(),
                        data: undefined,
                    },
                    ...line.data!.map(groupLine => ({
                        ...groupLine,
                        type: "group",
                        key: index.toString(),
                        data: {
                            ...groupLine,
                        },
                    })) ?? [],
                ];
            }
            return [];
        }).flat();
    }, [saleOrder.lines]);

    // Calculating orders summary
    const linesSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        transformedLines.forEach(line => {
            const linePrice = calculateOrderPrice(line?.data?.quantity ?? 0, line?.data?.unit_price ?? 0, line?.data?.discount ?? 0);
            subtotal += linePrice;
            taxes += linePrice * ((line?.data?.tax?.rate ?? 0) / 100);
        });

        const totalWithTaxes = subtotal + taxes;

        return {
            subtotal,
            taxes,
            totalWithTaxes
        }
    }, [transformedLines]);

    const blocks = useMemo<Record<"accent" | "references" | "address", InfoItem[]>>(() => ({
        accent: [
            {
                title: t("dropdowns.customer.label"),
                data: () => saleOrder?.customer.name ?? ""
            },
            {
                title: t("accentFields.orderDate.label"),
                data: () => saleOrder?.order_date.format("DD.MM.YYYY") ?? "-"
            },
            {
                title: t("accentFields.deliveryStatus.label"),
                data: () => <div>
                    <div>
                        {
                            t("accentFields.deliveryStatus.variants." + saleOrder.shipment_state)
                        }
                    </div>
                    {
                        !!saleOrder.delivery_date &&
                        <div>
                            ({saleOrder.delivery_date.format("DD.MM.YYYY")})
                        </div>
                    }
                </div>
            },
        ],
        references: [
            {
                title: t("dropdowns.ourReference.label"),
                data: () => saleOrder?.our_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.theirReference.label"),
                data: () => saleOrder?.their_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.paymentTerms.label"),
                data: () => saleOrder?.payment_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.deliveryTerms.label"),
                data: () => saleOrder?.delivery_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.documentLanguage.label"),
                data: () => saleOrder?.language?.name ?? "-"
            }
        ],
        address: [
            {
                title: t("fields.billingAddress.label"),
                data: () => (<>
                    <div
                        className="mb-[24px]">{_.join([saleOrder.billing_address?.street, saleOrder.billing_address?.city, saleOrder.billing_address?.country?.name].filter(x => !_.isEmpty(x)), ", ")}</div>
                    <BaseCheckbox
                        label={`${t("fields.billingAddress.checkboxes.useForDelivery")}`}
                        checked={saleOrder.is_billing_for_delivery}
                        disabled
                    />
                </>)
            }
        ]
    }), [saleOrder, t]);

    return (
        <div className="space-y-[40px]">
            <div className="p-[16px] rounded-[8px] bg-informationAccentBlock text-accent">
                <BaseInputsGrid cols={3}>
                    {
                        blocks.accent.map((item, index) => (
                            <div key={index}>
                                <div className="mb-[16px]">{item.title}</div>
                                <div className="space-x-[24px] font-semibold">{item.data()}</div>
                            </div>
                        ))
                    }
                </BaseInputsGrid>
            </div>
            <BaseInputsGrid title="References">
                {
                    blocks.references.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="space-x-[24px] text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>
            <BaseInputsGrid title="Address">
                {
                    blocks.address.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="text-accent">{item.data()}</div>
                        </div>
                    ))
                }
                {
                    !saleOrder?.is_billing_for_delivery &&
                    <div>
                        <div className="mb-[16px] font-semibold">{t("dropdowns.deliveryAddress.label")}</div>
                        <div className="space-x-[24px] text-accent">
                            {_.join([saleOrder.delivery_address?.street, saleOrder.delivery_address?.city, saleOrder.delivery_address?.country?.name].filter(x => !_.isEmpty(x)), ", ")}
                        </div>
                    </div>
                }
            </BaseInputsGrid>

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {
                    saleOrder?.lines.length
                        ?
                        <>
                            <div className="flex mb-[46px] items-center space-x-[8px]">
                                <div className="text-xl font-thin text-gray-600 grow">{t("orders.heading")}</div>
                            </div>

                            <BaseTable
                                data={transformedLines as TransformedLine[]}
                                columns={[
                                    {
                                        header: t("orders.columns.0"),
                                        getter: (row) => row.data ?
                                        row.type === "group" ? 
                                            <div className="flex items-center">
                                                <div className="self-center w-[2px] h-[20px] bg-[#D9D6DA] rounded-sm mr-2"></div> 
                                                <div>
                                                    {row?.data?.product?.code}
                                                    {row.data.comment && (
                                                        <BaseTooltip
                                                            title={row.data.comment}
                                                            placement="top"
                                                        >
                                                            <MessageSquareText className="w-5 ml-2 text-[#5A5587] hover:text-accent cursor-pointer" />
                                                        </BaseTooltip>
                                                    )}
                                                </div>
                                            </div> 
                                        : <div>
                                            {row?.data?.product?.code}
                                            {row.data.comment && (
                                                <BaseTooltip
                                                    title={row.data.comment}
                                                    placement="top"
                                                >
                                                    <MessageSquareText className="w-5 ml-2 text-[#5A5587] hover:text-accent cursor-pointer" />
                                                </BaseTooltip>
                                            )}
                                        </div>
                                        : row.type === "group" 
                                        ? 
                                        <div className="flex items-center">
                                        <div className="w-1 h-8 bg-[#B49FFB] rounded-sm mr-2"></div> 
                                        <div className="ml-3 absolute min-w-[120px] flex space-x-2">
                                            <div className="font-semibold">{row.name}</div> 
                                            <BaseBadge
                                                className="px-2 bg-[#CDCFFD] rounded-xl"
                                            >
                                                <div className="flex items-center">
                                                    <CollectionsIcon className="mr-1 bg-[#CDCFFD] text-accent" />
                                                    {row.module === "collection" && t("orders.groups.0")}
                                                </div>
                                            </BaseBadge>
                                        </div>
                                    </div> 
                                        : "",
                                        sx: {
                                            minWidth: "200px"
                                        }
                                    },
                                    {
                                        header: t("orders.columns.1"),
                                        getter: (row) => row.data ?
                                            row?.data?.product?.name : "",
                                    },
                                    {
                                        header: t("orders.columns.2"),
                                        getter: (row) => row.data ? row.data?.quantity : ""
                                    },
                                    {
                                        visible: ability.can("price.view", "sale_order"),
                                        header: t("orders.columns.3"),
                                        getter: (row) => row.data ? formatPriceWithCurrency(row.data?.unit_price, baseCurrency) : ""
                                    },
                                    {
                                        visible: ability.can("price.view", "sale_order"),
                                        header: t("orders.columns.4"),
                                        getter: (row) => row.data 
                                                        ? row.data?.discount 
                                                        ? normalizePrice(row.data?.discount) + "%"
                                                        : "-" 
                                                        : ""
                                    },
                                    {
                                        visible: ability.can("price.view", "sale_order"),
                                        header: t("orders.columns.5"),
                                        getter: (row) => row.data 
                                        ? row.data?.tax?.rate 
                                        ? row.data?.tax.rate + "%" 
                                        : "-"
                                        : ""
                                    },
                                    {
                                        visible: ability.can("price.view", "sale_order"),
                                        header: () => <div className="text-right">{t("orders.columns.6")}</div>,
                                        getter: (row) => row.data ? formatPriceWithCurrency(calculateOrderPrice(row.data?.quantity, row.data?.unit_price, row.data?.discount ?? 0), baseCurrency) : "",
                                        cellClasses: "!text-right"
                                    }
                                ]}

                                size={"small"}
                                rowOnClick={(row) => {
                                    if (row.type === "group" && !row.data) {
                                        toggleCollapse(row.key ?? '');
                                    }
                                }}
                                rowProps={(row) => {
                                    if (row.type === "group" && !row.data && row.key) {
                                        return {
                                            className: "group-header",
                                            style: {
                                                backgroundColor: collapsedGroups[row.key] ? "#FFFFFF" : "#EEF2FF",
                                            }
                                        };
                                    }
    
                                    if (row.type === "group" && !!row.data && row.key) {
                                        return {
                                            className: collapsedGroups[row.key] ? "collapsed" : "expanded",
                                            style: {
                                                height: collapsedGroups[row.key] ? "0px" : "auto",
                                                opacity: collapsedGroups[row.key] ? 0 : 1,
                                            }
                                        };
                                    }

                                    if (row.type === "group" && !!row.data) {
                                        return { className: "group-cell" };
                                    }
                                    
                                    return {};
                                }}
                                sx={{
                                    "& .collapsed": {
                                        display: "none", // Hide rows that are collapsed
                                    },
                                    "& .expanded": {
                                        display: "table-row", // Ensure expanded rows are shown
                                    },
                                    "& .MuiTableBody-root": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            display: "block",
                                            height: "16px",  // Height of the spacing
                                            backgroundColor: "transparent", // Transparent to keep it invisible
                                        },
                                    },
                                    "& .group-header": {
                                        "&:hover": {
                                            cursor: "pointer",
                                        }
                                    },
                                    "& .group-cell .MuiTableCell-root": {
                                        padding: "4px 16px",
                                    },
                                    "& .group-header .MuiTableCell-root:first-of-type": {
                                        borderTopLeftRadius: "8px",
                                        borderBottomLeftRadius: "8px",
                                    },
                                    "& .group-header .MuiTableCell-root:last-of-type": {
                                        borderTopRightRadius: "8px",
                                        borderBottomRightRadius: "8px",
                                    },
                                }}
                                headerSx={{
                                    background: "#F9F9F9"
                                }}
                            />
                            <Can I="price.view" a="sale_order">
                                <div className="flex justify-end mt-[48px] font-thin">
                                    <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                                        <div className="text-right">
                                            {t("orders.summary.subtotal")}
                                        </div>
                                        <div className="text-right pr-[16px]">
                                            {
                                                formatPriceWithCurrency(linesSummary.subtotal, baseCurrency)
                                            }
                                        </div>
                                        <div className="text-right">
                                            {t("orders.summary.taxes")}
                                        </div>
                                        <div className="text-right pr-[16px]">
                                            {
                                                formatPriceWithCurrency(linesSummary.taxes, baseCurrency)
                                            }
                                        </div>
                                        <div className="text-right font-semibold text-accent">
                                            {t("orders.summary.total")}
                                        </div>
                                        <div className="text-right pr-[16px] font-semibold text-accent">
                                            {
                                                formatPriceWithCurrency(linesSummary.totalWithTaxes, baseCurrency)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Can>
                        </>
                        :
                        <>
                            <div className="text-xl text-center text-gray-300 font-thin">
                                {t("orders.noProducts")}
                            </div>
                        </>
                }
            </div>
        </div>
    );
}

type InfoItem = {
    title: string;
    data: () => JSX.Element | string;
}
