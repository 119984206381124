import React, { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {
    PurchaseOrdersFormTyping
} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/types";
import { useTranslation } from "react-i18next";
import { useGetModuleEmployeesQuery } from "@redux/features/employees/employeesApi";
import { useGetModuleSupplierQuery } from "@redux/features/suppliers/suppliersApi";
import { useGetModulePaymentTermsQuery } from "@redux/features/paymentTerms/paymentTermsApi";
import { useGetModuleDeliveryTermsQuery } from "@redux/features/deliveryTerms/deliveryTermsApi";
import CreateOurReferenceModal from "./CreateOurReferenceModal";
import CreateTheirReferenceModal from "./CreateTheirReferenceModal";
import { Stack } from "@mui/material";
import { UserRoundSearch } from "lucide-react";
import _ from "lodash";


export default function ReferencesSection() {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general"});

    const {control, setValue} = useFormContext<PurchaseOrdersFormTyping>();

    // === Modals ===
    const [isNewOurReferenceModalOpen, setIsNewOurReferenceModalOpen] = useState<boolean>(false);
    const [isNewTheirReferenceModalOpen, setIsNewTheirReferenceModalOpen] = useState<boolean>(false);

    // === RTKQ ===
    // --> Our references (obtained from our employees)
    const {data: ourReferencesOptions, isLoading: isOurReferenceOptionsLoading} = useGetModuleEmployeesQuery("purchase-order");

    // --> Their references (obtained from the selected supplier)
    const supplier = useWatch({
        control,
        name: "supplier"
    })

    const mode = useWatch({
        control,
        name: "mode"
    });

    const {data: extendedSupplierData, isLoading: isExtendedSupplierDataLoading} = useGetModuleSupplierQuery(
        {module: "purchase-order", id: supplier?.id as number},
        {skip: !supplier}
    );

    useEffect(() => {
        if (extendedSupplierData && mode === "creation") {
            setValue("payment_terms", extendedSupplierData.payment_terms || undefined);
            setValue("delivery_terms", extendedSupplierData.delivery_terms || undefined);
        }
    }, [extendedSupplierData, setValue]);

    // --> Payment terms
    const {data: paymentTermsOptions, isLoading: paymentTermsOptionsLoading} = useGetModulePaymentTermsQuery("purchase-order");

    // --> Delivery terms
    const {data: deliveryTermsOptions, isLoading: deliveryTermsOptionsLoading} = useGetModuleDeliveryTermsQuery("purchase-order");
    return (
        <>
            <BaseInputsGrid title={t("categories.references")}>
                {/* Our references */}
                <Controller
                    name="our_reference"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            label={t("dropdowns.ourReference.label")}
                            placeholder={t("dropdowns.ourReference.placeholder")}
                            options={ourReferencesOptions}
                            getter={{
                                label: (opt) => opt.name,
                                key: (opt) => opt.id
                            }}

                            action={{
                                title: t("dropdowns.ourReference.buttons.create"),
                                onClick: () => setIsNewOurReferenceModalOpen(true)
                            }}

                            autocomplete
                            isLoading={isOurReferenceOptionsLoading}
                        />
                    )}
                />

                {/* Their references */}
                <Controller
                    name="their_reference"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            label={t("dropdowns.theirReference.label")}
                            placeholder={t("dropdowns.theirReference.placeholder")}
                            options={extendedSupplierData?.contacts}
                            getter={{
                                label: (item) => item.name,
                                key: (item) => item.id
                            }}

                            disabled={!supplier}
                            disableReason={
                                <Stack direction={"row"} spacing={2}>
                                    <UserRoundSearch size={20}/>
                                    <span>{t("dropdowns.theirReference.blockedBeforeSupplier")}</span>
                                </Stack>
                            }

                            action={{
                                title: t("dropdowns.theirReference.buttons.create"),
                                onClick: () => setIsNewTheirReferenceModalOpen(true)
                            }}

                            autocomplete
                            isLoading={isExtendedSupplierDataLoading}
                        />
                    )}
                />

                {/* Payment terms */}
                <Controller
                    name="payment_terms"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            label={t("dropdowns.paymentTerms.label")}
                            placeholder={t("dropdowns.paymentTerms.placeholder")}
                            options={paymentTermsOptions}
                            getter={{
                                label: (opt) => opt.name,
                                key: (opt) => opt.id
                            }}

                            autocomplete
                            isLoading={paymentTermsOptionsLoading || isExtendedSupplierDataLoading}
                        />
                    )}
                />

                {/* Delivery terms */}
                <Controller
                    name="delivery_terms"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            label={t("dropdowns.deliveryTerms.label")}
                            placeholder={t("dropdowns.deliveryTerms.placeholder")}
                            options={deliveryTermsOptions}
                            getter={{
                                label: (opt) => opt.name,
                                key: (opt) => opt.id
                            }}

                            autocomplete
                            isLoading={deliveryTermsOptionsLoading || isExtendedSupplierDataLoading}
                        />
                    )}
                />
            </BaseInputsGrid>

            <CreateOurReferenceModal
                isOpen={isNewOurReferenceModalOpen}
                onClose={() => setIsNewOurReferenceModalOpen(false)}
                onCreate={ourRef => {
                    setValue("our_reference", {
                        id: ourRef?.id,
                        name: ourRef?.name
                    });
                    setIsNewOurReferenceModalOpen(false);
                }}
            />

            <CreateTheirReferenceModal
                supplier={extendedSupplierData}
                isOpen={isNewTheirReferenceModalOpen}
                onClose={() => setIsNewTheirReferenceModalOpen(false)}
                onCreate={theirRef => {
                    const lastContact = _.last(theirRef?.contacts);
                    if (lastContact) {
                        setValue("their_reference", {
                            id: lastContact.id,
                            name: lastContact.name
                        });
                    }
                    setIsNewTheirReferenceModalOpen(false);
                }}
            />
        </>
    )
}