import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { locationSlimToOption } from "@helpers/utils";
import { LocationOption } from "@components/Dashboard/pages/Inventory/Adjustments/logic";
import { Location } from "@/types/general";

const locationsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLocations: builder.query<LocationOption[], void>({
            query: () => ({
                url: `location?slim=1`
            }),
            transformResponse: (response) => {
                const data = transformResponse(response);
                return data.map(locationSlimToOption).flat();
            },
            providesTags: [{ type: "Location", id: "LIST" }]
        }),

        getModuleLocations: builder.query<LocationOption[], string>({
            query: (module) => ({
                url: `${module}/location`
            }),
            transformResponse: (response) => {
                const data = transformResponse(response);
                return data.map(locationSlimToOption).flat();
            },
            providesTags: [{ type: "Location", id: "LIST" }]
        }),

        getLocationsNested: builder.query<Location.Slim[], void>({
            query: () => ({
                url: `location?slim=1`,
                ignoreDatesParsing: ["name"]
            }),
            transformResponse,
            providesTags: [{ type: "Location", id: "LIST" }]
        }),

        getModuleLocationsNested: builder.query<Location.Slim[], string>({
            query: (module) => ({
                url: `${module}/location`,
                ignoreDatesParsing: ["name"]
            }),
            transformResponse,
            providesTags: [{ type: "Location", id: "LIST" }]
        }),

        createLocation: builder.mutation<Location.Extended, Location.DTO.CreateUpdate>({
            query: (body) => ({
                url: `location`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: [{ type: "Location", id: "LIST" }]
        }),

        updateLocation: builder.mutation<Location.Extended, { id: number } & Location.DTO.CreateUpdate>({
            query: ({ id, ...body }) => ({
                url: `location/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            invalidatesTags: (result, error, arg) => [{ type: "Location", id: arg.id }]
        })
    })
});

export const {
    useGetLocationsQuery,
    useGetModuleLocationsQuery,
    useGetLocationsNestedQuery,
    useGetModuleLocationsNestedQuery,
    useCreateLocationMutation,
    useUpdateLocationMutation
} = locationsApi;