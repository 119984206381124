import React from "react";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import Fade from "@mui/material/Fade/Fade";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {SaleOrdersFormTyping} from "../../../types";
import {useTranslation} from "react-i18next";
import {Customer} from "@/types/general";
import CreateDeliveryAddressModal from "./CreateDeliveryAddressModal";
import BaseInput from "@reusables/BaseInput";
import {UserRoundSearch} from "lucide-react";
import {Stack} from "@mui/material";

type DeliveryAddressInputProps = {
    options: Customer.Components.DeliveryAddress[];
    isLoading: boolean;
}

export default function DeliveryAddressInput(props: DeliveryAddressInputProps) {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general.dropdowns.deliveryAddress"});

    const {control, setValue} = useFormContext<SaleOrdersFormTyping>();

    const isTheSameBillingForDelivery = useWatch({
        control,
        name: "is_billing_for_delivery"
    });

    const customer = useWatch({
        control,
        name: "customer"
    });

    const isInvoiced = useWatch({
        control,
        name: "is_invoiced"
    })

    const [isCreateDeliveryAddressModalOpen, setIsCreateDeliveryAddressModalOpen] = React.useState(false);

    if (!customer) {
        return <BaseInput
            className={"animate-out slide-out-to-top"}
            label={t("label") + " *"}
            disabled
            disableReason={
                <Stack direction={"row"} spacing={2}>
                    <UserRoundSearch size={20}/>
                    <span>{t("blockedBeforeCustomers")}</span>
                </Stack>
            }
        />
    }

    return (
        <>
            <Fade in={!isTheSameBillingForDelivery}>
                <div>
                    <Controller
                        name={"delivery_address"}
                        control={control}
                        render={({field, fieldState}) => (
                            <>
                                <BaseDropdown
                                    {...field}
                                    {...fieldState}

                                    label={`${t("label")} *`}
                                    placeholder={t("placeholder")}
                                    options={props.options}
                                    getter={{
                                        label: opt => opt.name,
                                        key: opt => opt.id,
                                        caption: opt => opt?.city ?? "",
                                    }}

                                    action={{
                                        title: t("creationModal.buttons.create"),
                                        onClick: () => setIsCreateDeliveryAddressModalOpen(true)
                                    }}

                                    disabled={isInvoiced}

                                    autocomplete
                                    virtualize
                                />
                            </>

                        )}
                    />
                </div>
            </Fade>

            {
                customer &&
                <CreateDeliveryAddressModal
                    customer_id={customer.id}
                    isOpen={isCreateDeliveryAddressModalOpen}
                    onClose={() => setIsCreateDeliveryAddressModalOpen(false)}
                    onCreation={delivery => {
                        setValue("delivery_address", delivery);
                        setIsCreateDeliveryAddressModalOpen(false);
                    }}
                />
            }
        </>
    )
}