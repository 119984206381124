import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useGetCountriesQuery } from "@redux/api/internalApiSlice";
import { useCreateCustomerDeliveryAddressMutation } from "@redux/features/customers/customersApi";
import { getCountryFlag } from "@helpers/utils";

import BaseModal from "@reusables/Modals/BaseModal";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseButton from "@reusables/BaseButton";
import BasePhoneInput from "@reusables/BasePhoneInput";

import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Customer } from "@/types/general";

interface CreateDeliveryAddressModalProps {
    customer_id: number;
    isOpen: boolean;
    onClose: () => void;
    onCreation: (address: Customer.Components.DeliveryAddress) => void;
}

const formScheme = z.object({
    name: z.string().trim(),
    country: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }).nullish(),
    street: z.string().trim().optional(),
    street_2: z.string().trim().optional(),
    zipcode: z.string().trim().optional(),
    city: z.string().trim().optional(),
    email: z.string().trim().optional(),
    contact_person: z.string().trim().optional(),
    phone: z.string().trim().optional()
});

type FormTyping = z.infer<typeof formScheme>

export default function CreateDeliveryAddressModal({
                                                       isOpen,
                                                       onClose,
                                                       onCreation,
                                                       customer_id
                                                   }: CreateDeliveryAddressModalProps): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.dropdowns.deliveryAddress.creationModal" });

    // ---> Countries selection <--- //
    const { data: countriesOptions, isLoading: countriesOptionsLoading } = useGetCountriesQuery();

    const [createDeliveryAddress, { isLoading: createDeliveryAddressLoading }] = useCreateCustomerDeliveryAddressMutation();

    const { control, handleSubmit, reset } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    useEffect(() => {
        reset();
    }, [reset, isOpen]);

    const onSubmit = handleSubmit((data) => {
        createDeliveryAddress({
            id: customer_id,
            name: data.name,
            country_id: data.country?.id,
            street: data.street,
            street_2: data.street_2,
            zipcode: data.zipcode,
            city: data.city,
            email: data.email,
            contact_person: data.contact_person,
            phone: data.phone,
            is_primary: false
        }).unwrap().then((delivery) => {
            onCreation(delivery);
            toast.success(t("responses.success"));
        }).catch(() => {
            toast.error(t("responses.error"));
        });

    });

    return (
        <BaseModal
            keepMounted={false}
            isOpen={isOpen}
            onClose={onClose}
            width={900}
            padding="56px"
            useCloseIcon
            isLoading={createDeliveryAddressLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="modal-title">{t("heading")}</div>
                <BaseInputsGrid gap={{ x: 24, y: 40 }} cols={2}>
                    <Controller
                        name={"name"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.name") + " *"}
                            />
                        )}
                    />

                    <Controller
                        name={"country"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}
                                label={t("dropdowns.country.label") + " *"}
                                placeholder={t("dropdowns.country.placeholder")}
                                options={countriesOptions}
                                getter={{
                                    label: (opt) => opt.name,
                                    key: (opt) => opt.id,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <img src={getCountryFlag(opt.code)} width={20} alt={opt.code} />
                                            <span className="ml-[8px] grow">{opt.name}</span>
                                            {icon}
                                        </div>
                                    )
                                }}

                                autocomplete
                                isLoading={countriesOptionsLoading}
                            />
                        )}
                    />

                    <Controller
                        name={"street"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.street")}
                            />
                        )}
                    />

                    <Controller
                        name={"street_2"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.street2")}
                            />
                        )}
                    />

                    <BaseInputsGrid gap={{ x: 24, y: 40 }} cols={2}>
                        <Controller
                            name={"zipcode"}
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.zipcode")}
                                />
                            )}
                        />

                        <Controller
                            name={"city"}
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.city")}
                                />
                            )}
                        />
                    </BaseInputsGrid>

                    <Controller
                        name={"email"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.email")}
                            />
                        )}
                    />

                    <Controller
                        name={"contact_person"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.contactName")}
                            />
                        )}
                    />

                    <Controller
                        name={"phone"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BasePhoneInput
                                {...field}
                                {...fieldState}
                                label={t("fields.phone")}
                                value={field.value || ""}
                                onChange={field.onChange}
                                size="md"
                                variant="custom"
                            />
                        )}
                    />
                </BaseInputsGrid>

                <BaseButton
                    buttonWidth="100%"
                    text={`${t("buttons.create")}`}
                    size="md"
                    type="submit"
                />
            </form>
        </BaseModal>
    );
}