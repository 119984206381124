import React, { useEffect, useMemo } from "react";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseTable from "@reusables/BaseTable";
import { useTranslation } from "react-i18next";
import { calculateOrderPrice, formatPriceWithCurrency, normalizePrice, smartParseFloat } from "@helpers/utils";
import { createPortal } from "react-dom";
import BaseInput from "@reusables/BaseInput";
import { ScanBarcode } from "lucide-react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ViewLayoutProps } from "./types";
import { Can, useAbility } from "@/casl.config";
import { PurchaseOrder } from "@/types/general";
import BaseBadge from "@reusables/BaseBadge";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";
import BaseCustomFieldsSection from "@/components/reusables/BaseCustomFieldsSection";


export interface TransformedLine {
    type: "line" | "group";
    id?: number;
    name?: string;
    module: "bom" | "collection";
    key?: string;
    data: PurchaseOrder.Components.LineData | undefined; // Always a single LineData or null for group headers
}

export default function OrderDetails({ purchaseOrder }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.general" });

    const ability = useAbility();

    const [collapsedGroups, setCollapsedGroups] = React.useState<Record<string, boolean>>({});

    const toggleCollapse = (groupKey: string) => {
        setCollapsedGroups(prevState => ({
            ...prevState,
            [groupKey]: !prevState[groupKey]
        }));
    };

    // Transforming lines to be flat
    const transformedLines = useMemo(() => {
        return purchaseOrder.lines.map((line, index) => {
            if (line.type === "line") {
                return {
                    ...line
                };
            } else if (line.type === "group") {
                return [
                    {
                        type: line.type,
                        id: line.id,
                        name: line.name,
                        module: line.module,
                        key: index.toString(),
                        data: undefined
                    },
                    ...line.data?.map(groupLine => ({
                        ...groupLine,
                        type: line.type,
                        key: index.toString(),
                        data: {
                            ...groupLine
                        }
                    })) ?? []
                ];
            }
            return [];
        }).flat();

    }, [purchaseOrder.lines]);

    // Calculating lines summary
    const linesSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        transformedLines.forEach(line => {
            const linePrice = calculateOrderPrice(line?.data?.quantity ?? 0, line?.data?.unit_price ?? 0, line?.data?.discount ?? 0);
            subtotal += linePrice;
            taxes += linePrice * ((line?.data?.tax?.rate ?? 0) / 100);
        });

        const shipping = smartParseFloat(purchaseOrder.shipping_cost) ?? 0;
        const totalWithTaxes = subtotal + taxes + shipping;

        return {
            subtotal,
            taxes,
            shipping,
            totalWithTaxes
        };
    }, [transformedLines, purchaseOrder.shipping_cost]);

    const blocks = useMemo<Record<"accent" | "references" | "address" | "shipping" | "currencies", InfoItem[]>>(() => ({
        accent: [
            {
                title: t("dropdowns.supplier.label"),
                data: () => purchaseOrder?.supplier.name ?? ""
            },
            {
                title: t("accentFields.status.label"),
                data: () => {
                    switch (purchaseOrder?.receive_state) {
                        case 1:
                            return <span>{t("accentFields.status.variants.notReceived")}</span>;
                        case 2:
                            return <span>{t("accentFields.status.variants.inProgress")}</span>;
                        case 3:
                            return <span>{t("accentFields.status.variants.received")}</span>;
                        default:
                            return <></>;
                    }
                }
            },
            {
                title: t("accentFields.orderDate.label"),
                data: () => purchaseOrder?.purchase_date.format("DD.MM.YYYY") ?? "-"
            },
            {
                title: t("accentFields.receivedAt.label"),
                data: () => purchaseOrder?.receive_date?.format("DD.MM.YYYY") ?? "-"
            }
        ],
        references: [
            {
                title: t("dropdowns.ourReference.label"),
                data: () => purchaseOrder?.our_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.theirReference.label"),
                data: () => purchaseOrder?.their_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.paymentTerms.label"),
                data: () => purchaseOrder?.payment_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.deliveryTerms.label"),
                data: () => purchaseOrder?.delivery_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.documentLanguage.label"),
                data: () => purchaseOrder?.language?.name ?? "-"
            }
        ],
        currencies: [
            {
                title: t("dropdowns.currency.label"),
                data: () => purchaseOrder?.currency ? purchaseOrder?.currency?.code.toUpperCase() + " (" + purchaseOrder?.currency?.name + ")" : "-"
            },
            {
                title: t("fields.exchangeRate.label"),
                data: () => purchaseOrder?.exchange_rate?.toPrecision(7) ?? "-"
            }
        ],
        address: [
            {
                title: t("fields.billingAddress.label"),
                data: () => (<>
                    <div
                        className="mb-[24px]">{_.join([purchaseOrder.billing_address?.street, purchaseOrder.billing_address?.city, purchaseOrder.billing_address?.country?.name].filter(x => !_.isEmpty(x)), ", ")}</div>
                    <BaseCheckbox
                        label={`${t("fields.billingAddress.checkboxes.useForDelivery")}`}
                        checked={purchaseOrder.is_billing_for_delivery}
                        disabled
                    />
                </>)
            }
        ],
        shipping: [
            {
                title: t("fields.shippingCost.label"),
                data: () => <div>
                    <div className="mb-[24px]">{purchaseOrder.shipping_cost?.toString() ?? "-"}</div>
                    <BaseCheckbox
                        label={`${t("fields.shippingCost.checkboxes.splitByProducts")}`}
                        checked={purchaseOrder.is_split_by_products}
                        disabled
                    />
                </div>
            }
        ]
    }), [purchaseOrder, t]);

    const [referenceNumberEl, setReferenceNumberEl] = React.useState<HTMLDivElement>();

    useEffect(() => {
        setReferenceNumberEl(document.getElementById("reference_number") as HTMLDivElement);
    }, []);

    return (
        <div className="space-y-[40px]">
            {
                purchaseOrder.reference_number && referenceNumberEl && createPortal(
                    <div>
                        <BaseInput
                            value={purchaseOrder.reference_number.code}
                            disabled
                            icon={{
                                right: {
                                    el: purchaseOrder.reference_number.id ?
                                        <Link to={`/dashboard/sales/orders/${purchaseOrder.reference_number.id}/details`}>
                                            <ScanBarcode color={"#a0a0a0"} />
                                        </Link>
                                        : <ScanBarcode color={"#a0a0a0"} />,
                                    offset: "12px"
                                }
                            }}
                        />
                    </div>,
                    referenceNumberEl
                )
            }
            <div className="p-[16px] rounded-[8px] bg-informationAccentBlock text-accent">
                <BaseInputsGrid cols={4}>
                    {
                        blocks.accent.map((item, index) => (
                            <div key={index}>
                                <div className="mb-[16px]">{item.title}</div>
                                <div className="space-x-[24px] font-semibold">{item.data()}</div>
                            </div>
                        ))
                    }
                </BaseInputsGrid>
            </div>
            <BaseInputsGrid title={t("categories.references")}>
                {
                    blocks.references.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="space-x-[24px] text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>
            <BaseInputsGrid>
                {
                    blocks.currencies.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="space-x-[24px] text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>
            <BaseInputsGrid title="Address">
                {
                    blocks.address.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="text-accent">{item.data()}</div>
                        </div>
                    ))
                }
                {
                    !purchaseOrder?.is_billing_for_delivery &&
                    <div>
                        <div className="mb-[16px] font-semibold">{t("dropdowns.deliveryAddress.label")}</div>
                        <div className="space-x-[24px] text-accent">
                            {purchaseOrder.delivery_address?.street}, {purchaseOrder.delivery_address?.city}, {purchaseOrder.delivery_address?.country?.name}
                        </div>
                    </div>
                }
                {
                    blocks.shipping.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>

            <BaseCustomFieldsSection.Previewer source={purchaseOrder} />

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {
                    purchaseOrder?.lines.length
                        ?
                        <>
                            <div className="flex mb-[46px] items-center space-x-[8px]">
                                <div className="text-xl font-thin text-gray-600 grow">{t("orders.heading")}</div>
                            </div>

                            <BaseTable
                                data={transformedLines as TransformedLine[]}
                                columns={[
                                    {
                                        header: t("orders.columns.0"),
                                        getter: (row) => row.data ?
                                            row.type === "group" ?
                                                <div className="flex items-center">
                                                    <div
                                                        className="self-center w-[2px] h-[20px] bg-[#D9D6DA] rounded-sm mr-2"></div>
                                                    <div>{row?.data?.product?.code}</div>
                                                </div>
                                                : row?.data?.product?.code
                                            : row.type === "group"
                                                ?
                                                <div className="flex items-center">
                                                    <div className="w-1 h-8 bg-[#B49FFB] rounded-sm"></div>
                                                    <div className="ml-3 absolute min-w-[120px] flex space-x-2">
                                                        <div className="font-semibold">{row.name}</div>
                                                        <BaseBadge
                                                            className="px-2 bg-[#CDCFFD] rounded-xl"
                                                        >
                                                            <div className="flex items-center">
                                                                <CollectionsIcon
                                                                    className="mr-1 bg-[#CDCFFD] text-accent" />
                                                                {row.module === "collection" ? t("orders.groups.0") : t("orders.groups.1")}
                                                            </div>
                                                        </BaseBadge>
                                                    </div>
                                                </div>
                                                : "",
                                        sx: { minWidth: "150px" }
                                    },
                                    {
                                        header: t("orders.columns.1"),
                                        getter: (row) => row.data ?
                                            row?.data?.product?.name : "",
                                        sx: { minWidth: "200px" }
                                    },
                                    {
                                        header: t("orders.columns.2"),
                                        getter: (row) => row.data ? row.data?.quantity : ""
                                    },
                                    {
                                        visible: ability.can("order_price.view", "purchase_order"),
                                        header: t("orders.columns.3"),
                                        getter: (row) => row.data ? formatPriceWithCurrency(row.data?.unit_price, purchaseOrder.currency) : ""
                                    },
                                    {
                                        visible: ability.can("order_price.view", "purchase_order"),
                                        header: t("orders.columns.4"),
                                        getter: (row) => row.data
                                            ? row.data?.discount
                                                ? normalizePrice(row.data?.discount) + "%"
                                                : "-"
                                            : ""
                                    },
                                    {
                                        visible: ability.can("order_price.view", "purchase_order"),
                                        header: t("orders.columns.5"),
                                        getter: (row) => row.data
                                            ? row.data?.tax?.rate
                                                ? row.data?.tax.rate + "%"
                                                : "-"
                                            : ""
                                    },
                                    {
                                        visible: ability.can("order_price.view", "purchase_order"),
                                        header: () => <div className="text-right">{t("orders.columns.6")}</div>,
                                        getter: (row) => row.data ? formatPriceWithCurrency(
                                            calculateOrderPrice(row.data?.quantity, row.data?.unit_price, row.data?.discount ?? 0) +
                                            (purchaseOrder.is_split_by_products ? ((purchaseOrder.shipping_cost ?? 0) / purchaseOrder.lines?.length) : 0),
                                            purchaseOrder.currency) : "",
                                        cellClasses: "!text-right"
                                    }
                                ]}

                                size={"small"}
                                rowOnClick={(row) => {
                                    if (row.type === "group" && !row.data) {
                                        toggleCollapse(row.key ?? "");
                                    }
                                }}
                                rowProps={(row) => {
                                    if (row.type === "group" && !row.data && row.key) {
                                        return {
                                            className: "group-header",
                                            style: {
                                                backgroundColor: collapsedGroups[row.key] ? "#FFFFFF" : "#EEF2FF"
                                            }
                                        };
                                    }

                                    if (row.type === "group" && !!row.data && row.key) {
                                        return {
                                            className: collapsedGroups[row.key] ? "collapsed" : "expanded",
                                            style: {
                                                height: collapsedGroups[row.key] ? "0px" : "auto",
                                                opacity: collapsedGroups[row.key] ? 0 : 1
                                            }
                                        };
                                    }

                                    if (row.type === "group" && !!row.data) {
                                        return { className: "group-cell" };
                                    }

                                    return {};
                                }}
                                sx={{
                                    "& .collapsed": {
                                        display: "none" // Hide rows that are collapsed
                                    },
                                    "& .expanded": {
                                        display: "table-row" // Ensure expanded rows are shown
                                    },
                                    "& .MuiTableBody-root": {
                                        position: "relative",
                                        "&::before": {
                                            content: "\"\"",
                                            display: "block",
                                            height: "16px",  // Height of the spacing
                                            backgroundColor: "transparent" // Transparent to keep it invisible
                                        }
                                    },
                                    "& .group-cell .MuiTableCell-root": {
                                        padding: "4px 16px"
                                    },
                                    "& .group-header": {
                                        "&:hover": {
                                            cursor: "pointer"
                                        }
                                    },
                                    "& .group-header .MuiTableCell-root:first-of-type": {
                                        borderTopLeftRadius: "8px",
                                        borderBottomLeftRadius: "8px"
                                    },
                                    "& .group-header .MuiTableCell-root:last-of-type": {
                                        borderTopRightRadius: "8px",
                                        borderBottomRightRadius: "8px"
                                    }
                                }}
                                headerSx={{
                                    background: "#F9F9F9"
                                }}
                            />
                            <Can I="order_price.view" a="purchase_order">
                                <div className="flex justify-end mt-[48px] font-thin">
                                    <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                                        <div className="text-right">
                                            {t("orders.summary.subtotal")}
                                        </div>
                                        <div className="text-right pr-[16px]">
                                            {formatPriceWithCurrency(linesSummary.subtotal, purchaseOrder.currency)}
                                        </div>
                                        <div className="text-right">
                                            {t("orders.summary.taxes")}
                                        </div>
                                        <div className="text-right pr-[16px]">
                                            {formatPriceWithCurrency(linesSummary.taxes, purchaseOrder.currency)}
                                        </div>
                                        {
                                            !purchaseOrder.is_split_by_products &&
                                            <>
                                                <div className="text-right">
                                                    {t("orders.summary.shipping")}
                                                </div>
                                                <div className="text-right pr-[16px]">
                                                    {formatPriceWithCurrency(linesSummary.shipping, purchaseOrder.currency)}
                                                </div>
                                            </>
                                        }
                                        <div className="text-right font-semibold text-accent">
                                            {t("orders.summary.total")}
                                        </div>
                                        <div className="text-right pr-[16px] font-semibold text-accent">
                                            {formatPriceWithCurrency(linesSummary.totalWithTaxes, purchaseOrder.currency)}
                                        </div>
                                    </div>
                                </div>
                            </Can>
                        </>
                        :
                        <>
                            <div className="text-xl text-center text-gray-300 font-thin">
                                {t("orders.noProducts")}
                            </div>
                        </>
                }
            </div>
        </div>
    );
}

type InfoItem = {
    title: string;
    data: () => JSX.Element | string;
}
