import { Product } from "@/types/general";
import { Constraint } from "@reusables/Validator/types";
import { z } from "zod";
import { refinements } from "@helpers/refinements";
import dayjs, { Dayjs } from "dayjs";
import { withCustomFields } from "@reusables/BaseCustomFieldsSection/logic";

const MAX_FILE_SIZE = import.meta.env.VITE_MAX_PRODUCT_IMAGE_SIZE;

/**
 * "Configurable" types refer to modified original types that make all fields optional, allowing them to be used for user input.
 */
export type ConfigurableVariant = {
    id?: number;
    images?: {
        id?: number;
        file: File;
    }[],
    name?: string;
    value?: string;
}; // for variants block

export type ConfigurableItemErrors<T> = { errors?: { [P in keyof T]?: Constraint<any>[] } }

/**
 * These are generalized properties associated with blocks that trigger collapsible (fadable) blocks and the collapsible blocks themselves.
 */
export type FadeInitiatorProperties = {
    connectedToggled: boolean;
    onToggleChange: (state: boolean) => void;
}
export type FadableProperties = {
    toggled: boolean;
}

export type ModalProperties = {
    isOpen?: boolean;
    onClose: () => void;
}

export type CreationModalProperties<T> = {
    onCreation: (item: T) => void;
} & ModalProperties;

export type DropdownProperties<T> = {
    selectedValues: T[];
    onSelection: (value: T[]) => void;
}

export type SerialNumber = {
    serial_number: string;
}

export type BatchNumber = {
    batch_number: string;
    expiration_date?: Dayjs | undefined;
}

export type DisTempField = Product.Components.DisableableTemplateField;

export type TemplateMode = "empty" | "selected" | "management"

// Refinements
const fileSizeRefinement = refinements.fileSize(MAX_FILE_SIZE, "MB");
const imageFormatRefinement = refinements.imageFormat;
const noSpacesRefinement = refinements.noSpaces;


export const rawProductScheme = withCustomFields(
    z.object({
        mode: z.union([z.literal("creation"), z.literal("editing")]),

        is_service: z.boolean(),

        id: z.number().nullish(),

        general: z.object({
            name: z.string().trim(),

            code: z.string().trim().nullish(),

            barcode: z.string().nullish().refine(noSpacesRefinement.refine, noSpacesRefinement.message),

            unit: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string().nullish()
            }).nullish(),

            supplier: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string().nullish()
            }).nullish(),

            category: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string().nullish(),
                is_service: z.boolean()
            }).nullish(),

            location: z.object({
                store: z.object({
                    id: z.number(),
                    name: z.string()
                }),
                section: z.object({
                    id: z.number(),
                    name: z.string()
                }).nullish()
            }).nullish(),

            tax: z.object({
                id: z.number(),
                name: z.string(),
                rate: z.number()
            }).nullish()
        }),


        // ======== PRICES ======== //
        prices: z.object({
            purchase: z.coerce.number().min(0),

            sale: z.coerce.number().min(0),

            extra_cost: z.coerce.number().min(0).nullish()
        }),

        // ======== Weights & Sizes ======== //
        weights: z.object({
            weight: z.coerce.number().nullish(),
            CBM: z.coerce.number().nullish(),
            width: z.coerce.number().nullish(),
            height: z.coerce.number().nullish(),
            length: z.coerce.number().nullish()
        }),

        // ======== OPTIONS ======== //
        options: z.object({
            is_batch_number: z.boolean(),
            is_serial_number: z.boolean(),
            is_component: z.boolean()
        }),

        // ======== Inventory ======== //
        inventory: z.object({
            in_stock: z.coerce.number().min(0).nullish(),
            min_inventory_quantity: z.coerce.number().min(0).nullish(),
            min_purchase_quantity: z.coerce.number().min(0).nullish(),
            min_sale_quantity: z.coerce.number().min(0).nullish()
        }),

        // ======== Description ======== //
        description: z.object({
            image: z.any().optional()
                .refine(fileSizeRefinement.refine, fileSizeRefinement.message)
                .refine(imageFormatRefinement.refine, imageFormatRefinement.message),
            description: z.string().nullish()
        }),

        batch_numbers: z.array(
            z.object({
                batch_number: z.string().nonempty(),
                expiration_date: z.instanceof(dayjs as unknown as typeof Dayjs).refine(value => value.isValid(), {
                    message: "Invalid date"
                }).optional()
            })
        ),

        serial_numbers: z.array(
            z.object({
                serial_number: z.string().nonempty()
            })
        )
    })
        .refine(scheme => {
                // Checking "code" field for spaces presence on "creation" mode
                return (scheme.mode === "creation" && noSpacesRefinement.refine(scheme.general.code)) || scheme.mode === "editing";
            },
            {
                params: noSpacesRefinement.message.params,
                path: ["general", "code"]
            }
        )
        // Making "location" required for the creation mode
        .superRefine(((data, ctx) => {
            console.log("Refining location", data.mode, data.is_service, data.general.location, data.mode === "creation" && !data.is_service && !data.general.location);
            if (data.mode === "creation" && !data.is_service && !data.general.location) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: {
                        i18n: {
                            key: "general.validation.global.required"
                        }
                    },
                    path: ["general", "location"]
                });
            }
        }))
        // Checking "batch_numbers" and "serial_numbers" correct filling (only on "creation" mode)
        .superRefine(((data, ctx) => {
            if (data.mode === "creation" && !data.is_service && data.inventory.in_stock) {
                if (
                    data.options.is_batch_number
                    &&
                    (
                        data.batch_numbers.length === 0
                        ||
                        data.batch_numbers.length > data.inventory.in_stock
                    )
                ) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        params: {
                            i18n: {
                                key: "products.createEditProduct.validation.batchNumbers"
                            }
                        },
                        path: ["batch_numbers"]
                    });
                }

                if (
                    data.options.is_serial_number
                    &&
                    data.serial_numbers.length != data.inventory.in_stock
                ) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        params: {
                            i18n: {
                                key: "products.createEditProduct.validation.serialNumbers"
                            }
                        },
                        path: ["serial_numbers"]
                    });
                }
            }
        }))
);

// Product RHF configuration
export const productScheme = z.intersection(
    z.object({
        parent: z.object({
            id: z.number().nullish(),
            name: z.string().nullish()
        }).nullish(),

        deleted_variants: z.array(z.number()),

        variants: z.array(rawProductScheme)
    }),
    rawProductScheme
);

export type ProductSchemeTyping = z.infer<typeof productScheme>;
export type ProductType = z.infer<typeof rawProductScheme>;

export type ProductSectionProps = {
    editing?: boolean;
}