import React from "react";

import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { useGetSaleOrderQuery, useUpdateSaleOrderMutation } from "@redux/features/sales/orders/salesOrdersApi";
import MutationLayout from "../components/MutationLayout";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { toastError } from "@helpers/utils";

export default function SaleOrderEditingPage() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.editing" });

    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [updateSaleOrder, { isLoading: isUpdateLoading }] = useUpdateSaleOrderMutation();
    const { data: saleOrder, isLoading: isSaleOrderLoading } = useGetSaleOrderQuery(parseInt(id));

    return (
        <BaseRecordRenderer
            record={saleOrder?.is_editable}
            loading={isSaleOrderLoading}
            notFoundText={t("notFoundText")}
        >
            <Can not I="edit" a="sale_order">
                <NoPermissionBanner />
            </Can>

            <Can I="edit" a="sale_order">
                <BaseLoadingBlocker active={isUpdateLoading}>
                    <div className="space-y-[40px]">
                        <div className="flex items-center">
                            <h6 className="text-accent grow">{t("heading")} #{saleOrder?.code ?? saleOrder?.id}</h6>
                        </div>
                        <MutationLayout
                            saleOrder={saleOrder}
                            onSubmit={data => {
                                const mutationData = {
                                    customer: data.customer.id,
                                    order_date: data.order_date.format("YYYY-MM-DD"),
                                    preferred_delivery_date: data.preferred_delivery_date?.format("YYYY-MM-DD"),
                                    our_reference: data.our_reference?.id,
                                    their_reference: data.their_reference?.id,
                                    payment_terms: data.payment_terms?.id,
                                    delivery_terms: data.delivery_terms?.id,
                                    language: data.language?.id,

                                    ...(!data.is_billing_for_delivery && !!data.delivery_address && {
                                        delivery_address: data.delivery_address.id
                                    }),

                                    is_billing_for_delivery: data.is_billing_for_delivery,

                                    // TODO: Uncomment when the backend will be ready to handle order line prices update
                                    lines: data.lines.filter(line => !!line.data).map(line => ({
                                        ...(line.id && { id: line.id }),
                                        ...(!line?.id &&
                                            {
                                                product: line.data?.product.id
                                            }),
                                        ...(line.data?.comment && { comment: line.data?.comment }),
                                        quantity: line.data?.quantity,
                                        unit_price: line.data?.unit_price,
                                        ...(line.data?.discount && { discount: line.data?.discount }),
                                        ...(line.data?.tax && { tax: line.data?.tax.id }),
                                        ...(line.module && {
                                            group: {
                                                id: line.group_id,
                                                module: line.module,
                                                key: line.key
                                            }
                                        })
                                    })),

                                    deleted_lines: data.deleted_lines
                                };

                                updateSaleOrder({
                                    id: saleOrder?.id as number,
                                    ...mutationData
                                })
                                    .unwrap()
                                    .then(() => {
                                        history.push(`/dashboard/sales/orders/${saleOrder?.id}/details`);
                                    })
                                    .catch(e => {
                                        toastError(e);
                                    });
                            }}
                        />
                    </div>
                </BaseLoadingBlocker>
            </Can>
        </BaseRecordRenderer>
    );
}