import React, { useEffect, useState } from "react";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Customer } from "@/types/general";
import GeneralTab from "@components/Dashboard/pages/Customers/Customer/components/tabs/GeneralTab";
import DiscountTab from "./DiscountTab";
import AddressTab from "@components/Dashboard/pages/Customers/Customer/components/tabs/AddressTab";
import CustomerStepper from "@components/Dashboard/pages/Customers/Customer/components/Stepper/CustomerStepper";
import { requiredIfAnyFilled } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import SettingsTab from "./SettingsTab";
import { refinements } from "@helpers/refinements";
import { createPortal } from "react-dom";
import BaseButton from "@reusables/BaseButton";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";

const ORDER_PRICE_PRECISION = import.meta.env.VITE_ORDER_PRICE_PRECISION;

const maxDecimalPrecisionRefinement = refinements.maxDecimalPrecision(ORDER_PRICE_PRECISION);

const validCRN = refinements.validateCRN;

export const contactScheme = z.array(
    z.object({
        id: z.number().optional(),
        name: z.string().optional(),
        phone: z.string().nullish(),
        email: z.string().email().nullish()
    }).superRefine((scheme, ctx) => {
        requiredIfAnyFilled(scheme, ["email", "phone"])
            .forEach(key => {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: {
                        i18n: "general.validation.global.required"
                    },
                    path: [key]
                });
            });
    })
);

export const personContactScheme = z.array(
    z.object({
        id: z.number().optional(),
        phone: z.string().optional(),
        email: z.string().email().optional()
    }).superRefine((scheme, ctx) => {
        requiredIfAnyFilled(scheme)
            .forEach(key => {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: {
                        i18n: "general.validation.global.required"
                    },
                    path: [key]
                });
            });
    })
);

const formScheme = z.object({
    mode: z.union([z.literal("creation"), z.literal("editing")]),

    name: z.string().trim().nonempty(),
    code: z.string().trim().optional(),
    registration_number: z.string().trim().refine(validCRN.refine, validCRN.message).optional(),

    is_person: z.boolean(),

    contacts: contactScheme.nullish(),

    person_contacts: personContactScheme.nullish(),

    deleted_contacts: z.array(z.number()),

    billing_address: z.object({
        name: z.string().nullish(),
        street: z.string().nullish(),
        street_2: z.string().nullish(),
        zipcode: z.string().nullish(),
        city: z.string().nullish(),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).nullish(),
        phone: z.string().nullish(),
        email: z.string().email().nullish(),
        is_used_for_shipping: z.boolean().nullish()
    }).superRefine((scheme, ctx) => {
        const hasNonNullishFields = _.some(
            _.omit(scheme, ["name", "is_used_for_shipping"]),
            (value) => !_.isNil(value) && value !== ""
        );
    
        if (hasNonNullishFields && (!scheme.name || scheme.name.trim() === "")) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Required",
                path: ["name"],
            });
        }
    }),

    deliveries: z.array(
        z.object({
            id: z.number().optional(),
            name: z.string().nullish(),
            street: z.string().nullish(),
            street_2: z.string().nullish(),
            zipcode: z.string().nullish(),
            city: z.string().nullish(),
            country: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string()
            }).nullish(),
            contact_person: z.string().nullish(),
            phone: z.string().nullish(),
            email: z.string().email().nullish(),
            is_primary: z.boolean().nullish()
        }).superRefine((scheme, ctx) => {
            const hasNonNullishFields = _.some(
                _.omit(scheme, ["name", "is_primary", "id"]),
                (value) => !_.isNil(value) && value !== ""
            );
        
            if (hasNonNullishFields && (!scheme.name || scheme.name.trim() === "")) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Required",
                    path: ["name"],
                });
            }
        })
    ).nullish(),

    deleted_deliveries: z.array(z.number()),

    language: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }).nullish(),

    tax_rate: z.object({
        id: z.number(),
        name: z.string().nullish(),
        rate: z.number()
    }).nullish(),

    discount: z.coerce.number().min(0).max(100).nullish(),

    payment_terms: z.object({
        id: z.number(),
        name: z.string()
    }).nullish(),

    delivery_terms: z.object({
        id: z.number(),
        name: z.string()
    }).nullish(),

    reference: z.object({
        id: z.number(),
        name: z.string()
    }).nullish(),

    products: z.array(z.object({
        product: z.object({
            id: z.number(),
            code: z.string(),
            name: z.string()
        }),
        sale_price: z.coerce.number().min(0)
            .refine(maxDecimalPrecisionRefinement.refine, maxDecimalPrecisionRefinement.message),
        discount: z.coerce.number().min(0).max(100).optional(),
        min_quantity: z.coerce.number().min(0).optional(),
        valid_from: z.instanceof(dayjs as unknown as typeof Dayjs).nullish(),
        valid_to: z.instanceof(dayjs as unknown as typeof Dayjs).nullish()
    })).nullish()
});

export type CustomerFormTyping = z.infer<typeof formScheme>

interface TabsControllerProps {
    customer?: Customer.Extended;
    onSubmit: (data: CustomerFormTyping) => void;
}

export default function TabsController({ customer, onSubmit }: TabsControllerProps) {
    const { t } = useTranslation("", { keyPrefix: "customers.general" });

    const methods = useForm<CustomerFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            contacts: [{}],
            person_contacts: [{}],
            deliveries: [{}],
            deleted_contacts: [],
            deleted_deliveries: [],
            is_person: false
            // products: [{}]
        }
    });

    const [activeTab, setActiveTab] = useState<number>(0);

    const onFormSubmit = methods.handleSubmit(onSubmit, console.error);

    useEffect(() => {
        if (customer) {
            const isPerson = customer.is_person ?? false;

            const data = {
                mode: "editing" as CustomerFormTyping["mode"],
                is_person: isPerson,
                name: customer.name,
                code: customer.code,
                registration_number: customer.registration_number ?? undefined,
                ...(!isPerson && { contacts: customer.contacts }),
                ...(isPerson && { contacts: customer.contacts }),
                billing_address: customer.billing ?? undefined,
                deliveries: customer.deliveries.length ? customer.deliveries : [{}],
                language: customer.language ?? undefined,
                tax_rate: customer.tax_rate,
                discount: customer.discount ?? undefined,
                payment_terms: customer.payment_terms ?? undefined,
                delivery_terms: customer.delivery_terms ?? undefined,
                reference: customer.reference ?? undefined,
                deleted_contacts: [],
                deleted_deliveries: [],
                products: customer.products ?? undefined
            };

            methods.reset(data);

            console.log("Data", data, isPerson, customer.contacts);
        } else {
            methods.reset({
                mode: "creation",
                is_person: false,
                contacts: [{}],
                person_contacts: [{}],
                deliveries: [{}],
                deleted_contacts: [],
                deleted_deliveries: []
                // products: [{}]
            });
        }
    }, [customer, methods.setValue]);

    const customerHeaderElement = document.getElementById("customer-header");

    return (
        <FormProvider {...methods}>
            {
                customer && customerHeaderElement && createPortal(
                    <BaseButton
                        text={t("save")}
                        size={"md"}
                        onClick={() => onFormSubmit()}
                    />,
                    customerHeaderElement
                )
            }
            <CustomerStepper
                tabs={[
                    {
                        title: t => t("general.heading"),
                        content: <GeneralTab
                            onNext={e => {
                                e.preventDefault();
                                setActiveTab(1);
                            }}
                            mode={customer ? "editing" : "creation"}
                        />
                    },
                    {
                        title: t => t("pricesAndDiscounts.heading"),
                        content: <DiscountTab
                            onPrev={() => {
                                setActiveTab(0);
                            }}
                            onNext={e => {
                                e.preventDefault();
                                setActiveTab(2);
                            }}
                            mode={customer ? "editing" : "creation"}
                        />
                    },
                    {
                        title: t => t("address.heading"),
                        content: <AddressTab
                            onPrev={() => {
                                setActiveTab(2);
                            }}
                            onNext={e => {
                                e.preventDefault();
                                setActiveTab(3);
                            }}
                            mode={customer ? "editing" : "creation"}
                        />
                    },
                    {
                        title: t => t("settings.heading"),
                        content: <SettingsTab
                            onPrev={() => {
                                setActiveTab(2);
                            }}
                            onNext={onFormSubmit}
                            mode={customer ? "editing" : "creation"}
                        />
                    }
                ]}

                manual={{
                    activeTab: activeTab,
                    onClick: (tab) => {
                        setActiveTab(tab);
                    }
                }}
            />
        </FormProvider>
    );
}